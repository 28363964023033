<script>
import ItemPreviewAtom from '@/components/items/ItemPreviewAtom.vue'
import Discounts from '@/classes/Modules/Discounts/Discounts'

export default {
  name: 'ItemPreview',
  components: {ItemPreviewAtom},
  props: {
    item: {
      required: true
    },
    discounts: {
      required: true,
      type: Discounts
    },
    hideReservationBtn: {
      type: Boolean,
      required: false,
      default: false
    },
    searchStart: {
      type: Number,
      required: false,
      default: null
    },
    searchEnd: {
      type: Number,
      required: false,
      default: null
    }
  }
}
</script>

<template>
  <v-carousel
    hide-delimiters
    :show-arrows="!!item.hasVariants()"
    height="100%"
    next-icon="fal fa-chevron-right"
    prev-icon="fal fa-chevron-left"
  >
    <v-carousel-item>
      <item-preview-atom
        :item="item"
        :discounts="discounts"
        :hide-reservation-btn="hideReservationBtn"
        :search-start="searchStart"
        :search-end="searchEnd"
        @ItemGrid:saveTmpSearch="$emit('ItemGrid:saveTmpSearch')"
      />
    </v-carousel-item>

    <!-- variants -->
    <v-carousel-item
      v-for="(variant, variantId) in item.getVariantItemsArray()"
      :key="variantId"
    >
      <item-preview-atom
        :item="variant"
        :discounts="discounts"
        :hide-reservation-btn="hideReservationBtn"
        :search-start="searchStart"
        :search-end="searchEnd"
        @ItemGrid:saveTmpSearch="$emit('ItemGrid:saveTmpSearch')"
      />
    </v-carousel-item>
  </v-carousel>
</template>
