export default {
  general: {
    confirmed: 'bestätigt',
    total: 'Total',
    yes: 'ja',
    no: 'nein',
    from: 'von',
    to: 'bis',
    at: 'um',
    and: 'und',
    cancel: 'Abbrechen',
    ok: 'ok',
    add: 'Hinzufügen',
    delete: 'Löschen',
    edit: 'Bearbeiten',
    save: 'Speichern',
    reallyDelete: 'Wirklich löschen?',
    tableSearch: 'Tabelle durchsuchen',
    rowsPerPageLabel: 'Zeilen pro Seite',
    search: 'Suchen',
    name: 'Name',
    surname: 'Nachname',
    lastname: 'Nachname',
    firstname: 'Vorname',
    address: 'Adresse',
    plz: 'PLZ',
    city: 'Ort',
    categories: 'Kategorien',
    back: 'Zurück',
    prev: 'Vorherige',
    next: 'Weiter',
    date: 'Datum',
    time: 'Zeit',
    machine: 'Maschine',
    language: 'Sprache',
    logo: 'Logo',
    phone: 'Telefon',
    mobile: 'Mobile',
    reservation: 'Reservation',
    renter: 'Mieter',
    lessor: 'Vermieter',
    print: 'Vorschau drucken',
    farMeSlug: 'Weniger Bürokratie - Mehr Landwirtschaft',
    confirm: 'Bestätigen',
    use: 'Nuztung',
    description: 'Beschreibung',
    me: 'ich'
  },
  menu: {
    incomingInvoices: 'Rechnungseingang',
    dashboard: 'Reservationen',
    items: 'Meine Maschinen',
    billing: 'Abrechnungen',
    myTeam: 'Mein Team',
    members: 'Mitglieder',
    employee: 'Mitarbeitende',
    settings: 'Meine Einstellungen',
    reservations: 'Archiv',
    newBilling: 'Neue Abrechnung',
    publishedBillings: 'Bestätigt',
    draftBillings: 'Entwürfe',
    notifications: 'Benachrichtigungen',
    reservationRequests: 'Reservationsanfragen',
    discounts: 'Tarifabstufungen',
    memberships: 'Mitgliedschaften',
  },
  localString: 'de-CH',
  notifications: {
    overviewWidgetTitle: 'Benachrichtigungen',
    all: 'Alle Benachrichtigungen'
  },
  changeUser: {
    changeToMe: 'Als {user}',
    permissionDenied: 'Mit dem aktuellen Benutzer haben Sie nicht die Berechtigung, auf diese Seite zuzugreifen.',
    changeUserModalTitle: 'Benutzer wechseln',
    changeUserModalExplanation: 'Als welcher Benutzer möchten Sie fortfahren?',
    selectUser: 'Benutzer wählen',
    changeUser: 'Benutzer wechseln'
  },
  invoice: {
    invoiceReceived: 'Betrag erhalten',
    pdfIsNotCreatedYet: 'Rechnung noch nicht als PDF verfügbar. Bitte versuchen Sie es später nocheinmal.',
    copyPdfLink: 'PDF Link kopieren',
    downloadPdf: 'PDF herunterladen',
    newDescription: 'Beschreibung Eintrag',
    newAmount: 'Betrag (CHF)',
    addCustomEntryModalTitle: 'Neue Rechnungsposition erfassen',
    receiver: 'Empfänger',
    sender: 'Absender',
    invoice: 'Rechnung',
    invoiceNr: 'Rechnungs-Nr',
    use: 'Nutzung',
    balance: 'Saldo',
    pricePerUnit: 'Preis/Einh.',
    totalExklTaxes: 'Total exkl. Mwst.',
    taxrate: 'Mwst.',
    totalInklTaxes: 'Total inkl. Mwst.',
    payConditions: 'Bitte Betrag innerhalb 30 Tagen ab Rechnungsdatum überweisen.',
    iban: 'IBAN',
    addOwnEntry: 'Eintrag hinzufügen',
    appUserMissing: 'Unerwarteter Fehler: "AppUserInstance not available".',
    missingIban: 'Ungültige IBAN',
    provideIban: 'Ergänzen Sie die IBAN in Ihren Einstellungen.'
  },
  billing: {
    publishedCustomInvoiceTitle: 'Bestätigte Einzelrechnungen',
    addReceiver: 'Rechnungsempfänger',
    createNewCustomInvoiceModalTitle: 'Neue Einzelrechnung',
    customInvoiceTitle: 'Einzelrechnungen Entwurf',
    addNewCustomInvoice: 'Einzelrechnung',
    isPdfReady: 'Status PDF',
    invoicePdfIsReady: 'bereit',
    pdfIsGenerating: 'in Arbeit...',
    invoicePaid: 'Erhalten?',
    pdfIsNotCreatedYet: 'Rechnung noch nicht als PDF verfügbar. Bitte versuchen Sie es später nocheinmal.',
    publishedBillingsTitle: 'Bestätigte Abrechnungen',
    reallyConfirm: 'Sind Sie sicher?',
    reallyConfirmBillingExplanation: 'Sie sind im Begriff, die Abrechnung zu bestätigen. Die Rechnungen können danach nicht mehr bearbeitet werden. ' +
      '<b>Dies kann NICHT rückgängig gemacht werden!</b>',
    showInvoices: 'Rechnungen anzeigen',
    showInvoice: 'bearbeiten',
    confirmBilling: 'Abrechnung per Mail versenden!',
    confirmInvoice: 'bestätigen',
    whatToDoModalTitle: 'Was möchten Sie tun?',
    deleteBilling: 'Abrechnung löschen',
    deleteInvoice: 'löschen',
    noSelectedReservations: 'Sie haben keinen Monat zum Abrechnen ausgewählt.',
    selectedReservationsModalTitle: 'Ihre ausgewählten Reservationen',
    showAllSelectedReservations: 'Zeige alle Reservationen im ausgewählten Zeitraum an.',
    noDraftBillings: 'Sie haben keine Abrechnungen im Entwurfsmodus.',
    billingDeleteExplanaition: 'Die Abrechnung und die darin enthaltenen Rechnungen werden gelöscht. Die Aktion kann nicht rückgängig gemacht werden. Sie können aber jederzeit eine neue Abrechnung erstellen.',
    draftState: 'Entwurf',
    receiver: 'Empfänger',
    billingDetailsTitle: 'Details Abrechnung',
    showMoreDetails: 'Details Abrechnung anzeigen',
    invoicesHeaderText: 'Abrechnung',
    Mwst: 'Mwst.',
    period: 'Periode',
    billingNameTable: 'Abrechnung',
    doNotCorrect: '! Mit Fehlbetrag korrigieren !',
    newBillingError: 'Beim Generieren der Abrechnung ist leider ein Fehler aufgetreten',
    newBillingSuccessfull: 'Die Abrechnung wurde erfolgreich generiert.',
    giveBillingMwst: 'Möchten Sie auf die Abrechnung Mehrwertsteuern verrechnen?',
    billingMwst: 'Mwst. %',
    billingNameTitle: 'Name der Abrechnung',
    giveBillingAName: 'Geben Sie dieser Abrechnung einen Namen',
    endUse: 'Definitiver Gebrauch durch Mieter',
    counterStart: 'Stand Zähler zu Beginn Nutzung',
    counterEnd: 'Stand Zähler am Ende Nutzung',
    correct: 'Zähler korrigieren',
    solveConflictModalTitle: 'Zählerkonflikt lösen',
    missingAmount: 'Fehlbetrag zwischen A und B',
    draftBillingsTitle: 'Abrechnungen Entwurf',
    openReservationsToClose: 'Einige Reservationen in ihrer gewünschten Abrechnungsperiode haben noch keinen Gebrauch eingetragen. Sie müssen diese zuerst abschliessen.',
    renter: 'Mieter',
    reservationDate: 'Reservation',
    billingNameExplanaition: 'Geben Sie der Abrechnung einen Namen',
    billingName: 'Name der Abrechnung',
    complete: 'Abrechnung generieren',
    wantToConfirmCreationOfNewBilling: 'Möchten Sie die Abrechnung vom {billingFrom} bis zum {billingTo} generieren?',
    noConflictingReservations: 'Alles bestens! Keine Reservation weist einen Zähler-Konflikt auf.',
    noOpenReservations: 'Alles bestens! Es gibt keine offenen Reservationen in Ihrem Zeitraum. Sie können einfach weiter klicken.',
    conflictingReservations: 'Konflikte Zähler',
    timePeriod: 'Zeitraum auswählen',
    openReservations: 'Offene Reservationen',
    youHaveNoInvoiceReservations: 'Sie haben {count} Reservationen, die verrechnet werden müssen.',
    newBilling: 'Abrechnung erstellen',
    totalChfWithoutDiscount: 'Total ohne Rabatt (chf)',
    searchInvoice: 'Suche nach Name, Nachname oder Stadt'
  },
  paginator: {
    loadMore: 'mehr laden',
    perPage: 'Elemente pro Seite'
  },
  setAmount: {
    wantToSplitReservationTitle: 'Sicher?',
    wantToSplitReservationText: 'Wollen Sie die Reservation wirklich aufteilen? Die Aktion kann nicht rückgängig gemacht werden!',
    splitReservation: 'Reservation aufteilen',
    startCounterShort: 'Zähler Beginn',
    endCounterShort: 'Zähler Ende',
    amount: 'Gebrauch eintragen',
    amountShort: 'Gebrauch',
    hasNoCounterExplain: 'Wie viel haben Sie die Maschine benutzt?',
    endCounter: 'Ende Nutzung: Stand Zähler',
    startCounter: 'Beginn Nutzung: Stand Zähler',
    setCounters: 'Bitte tragen Sie den Stand des Zählers vor Beginn und nach Beenden Ihrer Reservation in die Felder unten ein.',
    modalTitle: 'Erfassen',
    negativeValuesNotAllowed: 'Negative Werte sind nicht erlaubt',
    commentRequired: 'Teilen Sie dem Vermieter mit, weshalb Sie die Maschine nicht benutzt haben.'
  },
  user: {
    machines: 'Maschinenpark',
    profile: 'User Profil'
  },
  settings: {
    passwordIsNotUid: 'Das Passwort darf nicht Email, Vorname, Nachname, Adresse, PLZ, Ort oder Telefonnummern enthalten',
    passwordHasSmallLetter: 'Das Passwort muss mindestens einen Kleinbuchstaben enthalten.',
    passwordHasCapitalLetter: 'Das Passwort muss mindestens einen Grossbuchstaben enthalten.',
    passwordHasSpecialCharacter: 'Das Passwort muss mindestens ein Sonderzeichen enthalten.',
    passwordHasNumber: 'Das Passwort muss mindest eines Zahl enthalten.',
    passwordEqual: 'Die Passwörter müssen übereinstimmten',
    passwordLength: 'Das Passwort muss mindestens 8 Zeichen lang sein.',
    password1: 'Bitte geben Sie Ihr neues Passwort ein',
    password2: 'Bitte wiederholen Sie Ihr neues Passwort',
    changePassword: 'Passwort ändern',
    company: 'Betriebs-Art',
    myMembersArePublic: 'Meine Mitgliederliste ist öffentlich',
    mwst: 'MWST',
    iban: 'IBAN',
    moreOptions: 'Einstellungen',
    headerTitle: 'Meine Einstellungen'
  },
  login: {
    autoLogin: 'In Zukunft automatisch einloggen (Wir speichern dazu Cookies).',
    successfull: 'Sie haben sich erfolgreich angemeldet'
  },
  dashboard: {
    reservationIsNotConfirmed: 'Reservationsbestätigung ausstehend',
    noRequests: 'Sie haben zurzeit keine Reservationsanfragen',
    closeOpenReservation: 'Reservation abschliessen',
    closeOpenReservationFirstModalTitle: 'Offene Reservation',
    closeOpenReservationFirstInformation: 'Sie müssen die Reservation zuerst abschliessen',
    startReservation: 'Start Reservation',
    endReservation: 'Ende Reservation',
    toDebit: 'Zulasten von',
    reservationSplitModalTitle: 'Reservation aufteilen',
    splitReservation: 'Oder die Reservation aufteilen',
    doYouWantToSendThisProposition: 'Wollen Sie diesen Vorschlag schicken?',
    newReservationProposition: 'Machen Sie einen neuen Vorschlag',
    deleteRequestModalTitle: 'Reservation ablehnen?',
    reallyDeleteRequest: 'Wollen Sie die Reservation wirklich ablehnen?',
    rejectRequestButton: 'Ablehnen',
    acceptRequestButton: 'Bestätigen',
    editRequestButton: 'Neuer Vorschlag',
    detailRequestModalTitle: 'Was möchten Sie tun?',
    reservationRequestsTitle: 'Reservationsanfragen',
    youHaveOverlappingTimeSlots: 'Ihre Wahl ist ungültig, weil Sie sich mit anderen Reservationen überschneidet.',
    editReservation: 'ändern',
    reservationEditModalTitle: 'Reservation bearbeiten',
    doYouWantToDeleteTheReservation: 'Sollen wir die Reservation wirklich stornieren?',
    reservationDeleteModalTitle: 'Wirklich stornieren?',
    cancel: 'stornieren',
    previousRenter: 'Vorheriger Mieter',
    followingRenter: 'Auf Sie folgender Mieter',
    machineInstructions: 'Anleitung',
    reservationDetailsModalTitle: 'Details Reservation',
    endReservationModalTitle: 'Reservation beenden?',
    endReservationModalBody: 'Wollen Sie die Maschine jetzt zurückgeben und die Reservation beenden? Die Person der Nachfolgereservation wird evt. benachrichtigt.',
    extendReservationModalTitle: 'Reservation verlängern?',
    extendReservationModalBody: 'Sollen wir versuchen, diese Reservation eine Stunde zu verlängern?',
    noUpcomingReservations: 'Sie haben keine anstehenden Reservationen',
    bringBackUntil: 'Zurückbringen bis',
    openReservations: 'Zum Abschliessen',
    yourCurrentReservations: 'Laufende Reservation',
    upcommingReservations: 'Anstehende Reservationen',
    makeReservation: 'Maschine reservieren',
    closeReservation: 'Reservation abschliessen',
    machine: 'Maschine',
    owner: 'Besitzer',
    resFrom: 'Reservation von',
    resTo: 'Reservation bis',
    resUse: 'Gebrauch',
    reservationRequestsFromLessor: 'Reservationsanfragen (Ich miete)',
    reservationRequestsFromRenter: 'Reservationsanfragen (Ich vermiete)',
    membershipRequests: 'Anfragen Mitgliedschaft',
    customNotifications: 'Nachricht an Mitglieder'
  },
  employee: {
    headerSearch: 'Mitarbeitende hinzufügen',
    myEmployee: 'Meine Mitarbeitenden',
    deleteEmployeeExplanaition: 'Der Mitarbeitende wird aus ihrem Team entfernt und verliert die Rechte, z.B. für Sie Maschinen zu reservieren etc.',
    addModalTitle: 'Person zu Ihren Mitarbeitern hinzufügen?',
    addModalBody: 'Wenn Sie diese Person zu Ihren Mitarbeitern hinzufügen, erhält diese verschiedene Rechte. So kann die Person z.B. Maschinen unter ihrem Namen reservieren.'
  },
  reservation: {
    telOnly: 'Nur telefonisch',
    telOnlyModalTitle: 'Kontakt Eigentümer',
    gotToUserProfilLink: 'Benutzerprofil ansehen',
    failExplanation: 'Sie versuchen, eine bereits reservierte Zeitspanne zu reservieren. Möglicherweise ist Ihnen jemand beim Reservieren zuvorgekommen. Versuchen Sie es erneut mit einem anderen Datum',
    success: 'Reservation erfolgreich',
    fail: 'Reservation fehlgeschlagen!',
    reservationCorrect: 'Stimmt die Reservation?',
    getReservations: 'Reservationen werden geladen',
    nextWithConnectedItem: 'Weiter',
    nextWithoutConnectedItem: 'Weiter ohne Kombination',
    removeConnectedItem: 'Abwählen',
    addConnectedMachine: 'Kombinieren',
    connectedMachineTitle: 'Diese Maschine lässt sich gut kombinieren!',
    myTeamsTab: 'Maschinen Mitgliedschaften',
    myFavoritesTab: 'Favoriten',
    advancedSearch: 'Erweiterte Suche',
    myMachines: 'Meine Maschinen',
    category: 'Kategorie',
    fromAdvanced: 'Von',
    toAdvanced: 'Bis',
    distance: 'Umkreis',
    myLocation: 'Von meinem Standort im Umkreis von',
    searchList: 'Liste durchsuchen',
    tooBigRadiusAndSelectCategory: 'Wählen Sie eine Kategorie. Der Radius darf maximal 100 Kilometer betragen.',
    noItemsFound: 'Keine Maschinen anzuzeigen.',
    location: 'Standort der Maschine',
    price: 'Preis',
    reservation: 'Reservieren',
    addItemAsFavorite: 'Maschine als Favorit hinzufügen (klicken)',
    removeItemFromFavorite: 'Maschine als Favorit entfernen (klicken)',
    searchForItems: 'Suchen Sie eine Maschine, die Sie mieten möchten',
    owner: 'Besitzer',
    showReservation: 'reservation ansehen',
    variantModalTitle: 'Welche Ausführung von «{itemName}» möchten Sie reservieren?'
  },
  calendar: {
    reservationsOn: 'Reservationen am: ',
    reservedBy: 'reserviert von ',
    weekDayNames: {
      mo: 'mo',
      tu: 'di',
      we: 'mi',
      th: 'do',
      fr: 'fr',
      sa: 'sa',
      su: 'so'
    },
    monthNames: {
      january: 'januar',
      february: 'februar',
      march: 'märz',
      april: 'april',
      may: 'mai',
      june: 'juni',
      july: 'juli',
      august: 'august',
      september: 'september',
      october: 'oktober',
      november: 'november',
      december: 'dezember'
    },
    select: 'wählen'
  },
  items: {
    telOnly: 'Die Maschine kann nur telefonisch reserviert werden',
    myMachines: 'Meine Maschinen',
    addNew: 'Vermieten',
    title: 'Titel',
    addImgTitle: 'Bild hinzufügen',
    itemDescription: 'Beschreibung Maschine',
    categories: 'Kategorien auswählen *',
    rules: 'Regeln festlegen',
    maxDistance: 'Maximale Vermietdistanz (km)',
    maxTime: 'Maximale Reservationszeit (h)',
    reservationValidation: 'Eine Reservation muss von mir bestätigt werden',
    hasCounter: 'Die Maschine hat einen Zähler',
    location: 'Standort der Maschine',
    teamOnly: 'Maschine nur meinen Mitgliedern vermieten',
    priceTeam: 'Preis für meine Mitglieder',
    priceGlobal: 'Preis für Nicht-Mitglieder',
    pricePerUnit: 'Preis pro Einheit *',
    active: 'Status Maschine',
    showOptions: 'Mehr Optionen anzeigen',
    disableOptions: 'Optionen ausblenden',
    connectMachinesTitle: 'Diese Maschine kombinieren',
    connectMachineSelect: 'Diese Maschine als Kombination vermieten mit...',
    reallyDelete: 'Wirklich löschen?',
    reallyDeleteDescription: 'Diese Maschine wird gelöscht und von Ihrem Profil entfernt! Dies kann nicht rückgängig gemacht werden.',
    deactivateItem: 'Maschine wird deaktiviert',
    updateItem: 'Maschine aktualisieren',
    table: {
      name: 'Name',
      title: 'Titel',
      address: 'Adresse'
    },
    largerThanZeroRequired: 'Muss höher als 0 sein'
  },
  teams: {
    addHeader: 'Mitglieder hinzufügen',
    myTeam: 'Meine Mitglieder',
    searchPlaceholder: 'Nach Name, Nachname, PLZ oder Ort suchen',
    searchResultsHeader: 'Suchresultate',
    reallyAddTeam: 'Wollen Sie das Team dieser Person zu Ihrem Team hinzufügen?',
    addTeamExplanaition: 'Wenn Sie diese Aktion ausführen, werden die Team-Mitglieder dieser Person zu Ihrem Team hinzugefügt. Diese Aktion kann nicht rückgängig gemacht werden!',
    deleteMemberExplanaition: 'Damit entfernen Sie die Person aus Ihrem Team. Sie können der Person später wieder eine Anfrage für Mitgliedschaft senden.',
    reallyAddTitle: 'Anfrage muss von der Person bestätigt werden.',
    reallyAddBody: 'Die Person muss Ihre angefragte Mitgliedschaft zuerst bestätigen, bevor die Mitgliedschaft aktiv wird. Wenn Sie die Person zu Ihren Mitgliedern hinzufügen, kann diese ihre Maschinen mieten und von ihren allenfalls vergünstigten Tarifen profitieren.',
    memberPrivate: 'Die Mitgliederliste ist privat'
  },
  notify: {
    invoicePublished: 'Die Rechnung wurde publiziert',
    invoiceNotPublished: 'Die Rechnung konnte nicht publiziert werden',
    invoiceSuccessfullyDeleted: 'Die Rechnung wurde gelöscht',
    invoiceNotDeleted: 'Wir konnten die Rechnung nicht löschen',
    customEntryDeleted: 'Position wurde gelöscht',
    customEntryNotDeleted: 'Position konnte nicht gelöscht werden',
    endCounterIsSmallerThanStartCounter: 'Der Endzähler kann nicht kleiner sein, als der Startzähler',
    amountHasToBeHigherThanZero: 'Der Betrag muss höher als 0 sein',
    toHighAmount: 'Ihr Betrag ist zu hoch',
    isLastEndCounter: 'Sie können die Reservation nicht weiter teilen',
    selectUser: 'Bitte wählen Sie eine Person aus',
    endCounterToHigh: 'Der Zähler ist zu hoch',
    allreadyBilled: 'Die Reservation ist bereits verrechnet und kann nicht aufgeteilt werden.',
    splittingSuccess: 'Die Aufteilung der Reservation war erfolgreich',
    splittingFailed: 'Die Aufteilung der Reservation schlug fehl.',
    newMessage: 'Sie haben neue Nachrichten!',
    messagesNotLoaded: 'Wir konnten die Nachrichten leider nicht laden',
    contactDataCouldNotBeLoaded: 'Wir konnten die Kontaktdaten leider nicht laden',
    newRequestSent: 'Ihr neuer Vorschlag wurde verschickt',
    requestDeclined: 'Die Reservation wurde abgelehnt',
    selectTimeSpan: 'Sie müssen eine Zeitspanne auswählen',
    minimumOneDay: 'Sie müssen ein Datum auswählen',
    reservationsCouldNotBeLoaded: 'Wir konnten die Reservationen leider nicht laden.',
    requestSuccessfullyConfirmed: 'Die Reservation wurde erfolgreich bestätigt.',
    reservationWasSucessfullyUpdated: 'Wir konnten Ihre Reservation erfolgreich ändern',
    reservationwasNotUpdated: 'Leider konnten wir Ihre Reservation nicht ändern',
    reservationDeleted: 'Wir haben die Reservation storniert.',
    counterCanNotBeChangedBecauseOfReservations: 'Der Zähler kann nicht geändert werden, weil es bereits Reservationen gibt.',
    billingSuccessfullyPublished: 'Die Abrechnung wurde erfolgreich bestätigt.',
    customInvoiceEntrySuccessfullAdded: 'Die Rechnung wurde mit Ihrem Eintrag ergänzt',
    invoiceGenerationFailed: 'Wir konnten die Rechnung leider nicht generieren.',
    monthIsNotInPast: 'Sie können nur Monate auswählen, die hinter dem aktuellen Monat liegen.',
    monthHasToBeCoherent: 'Die ausgewählten Monate müssen zusammenhängend sein.',
    isNotSelectable: 'Dieser Monat kann nicht ausgewählt werden. Er ist bereits abgeschlossen',
    yearNotLoaded: 'Wir konnten die Daten des gewünschten Jahres leider nicht laden',
    deleteBillingSuccess: 'Wir haben die Abrechnung erfolgreich gelöscht',
    deleteBillingFail: 'Leider konnten wir die Abrechnung nicht löschen. Es ist ein Fehler aufgetreten.',
    aMonthHasToBeSelected: 'Sie müssen mindestens einen Monat auswählen.',
    hasConflicts: 'Sie müssen zuerst die Zählerkonflikte lösen.',
    closeOpenReservationsFirst: 'Sie müssen zuerst alle noch offenen Reservationen schliessen. Klicken Sie hierzu auf eine Reservation.',
    reservationEnd: 'Wir haben die Reservation storniert',
    amountSetSuccessfully: 'Der Eintrag war erfolgreich',
    amountSetNotSuccessfull: 'Leider konnten wir die Reservation nicht abschliessen',
    passwordChangeFailed: 'Wir konnten das Passwort nicht ändern',
    passwordChanged: 'Wir haben Ihr Passwort geändert',
    userDataNotLoaded: 'Die User-Daten konnten wir leider nicht laden',
    userUpdateFailed: 'Leider konnten wir die Änderungen nicht speichern',
    userUpdated: 'Die Änderungen wurden gespeichert',
    reservationExtendMaxTime: 'Die Maschine kann nur eine bestimmte Zeitdauer reserviert werden. Sie müssen eine neue Reservation erstellen.',
    reservationExtendHasFollowingReservation: 'Die Maschine ist nach Ihnen von einer anderen Person reserviert worden.',
    reservationExtendNeedValidation: 'Die Reservations-Verlängerung muss erst vom Besitzer der Maschine bestätigt werden.',
    reservationExtendTitle: 'Die Reservation konnte nicht verlängert werden',
    unkonwnError: 'Sorry! Ein unbekannter Fehler ist aufgetreten. Bitte wenden Sie sich an den Support. info-de@farmx.ch',
    reservationExtended: 'Wir haben Ihre Reservation verlängert.',
    upcomingReservationsNotLoaded: 'Die anstehenden Reservationen konnten leider nicht geladen werden',
    openReservationsLoadFail: 'Leider konnten wir Ihre offenen Reservationen nicht laden',
    unwantedReservations: 'Wir konnten eine Reservation nicht rückgängig machen. Bitte überprüfen Sie Ihre Reservationen.',
    overlappingSlots: 'Sie haben bereits reservierte Zeitfenster markiert. Dies ist nicht möglich',
    teamAdded: 'Die Mitglieder wurden Ihrer Mitgliederliste hinzugefügt',
    teamNotAdded: 'Das Team konnte nicht hinzugefügt werden',
    imageDeleted: 'Wir haben das Bild gelöscht',
    imageNotDeleted: 'Leider konnten wir das Bild nicht löschen',
    searchNotSuccessfull: 'Ihre Suche ergab keine Treffer.',
    error: 'Error: ',
    noTeam: 'Keine Teammitglieder gefunden',
    memberDeleted: 'Teammitglied erfolgreich entfernt',
    memerDeleteFailed: 'Teammitglied konnte nicht gelöscht werden',
    itemAdded: 'Maschine wurde hinzugefügt',
    itemNotAdded: 'Maschine konnte nicht hinzugefügt werden',
    itemUpdated: 'Die Änderungen wurden gespeichert',
    itemNotUpdated: 'Maschine konnte nicht aktualisiert werden',
    itemsLoadFail: 'Es konnten keine Maschinen geladen werden',
    itemLoadFailed: 'Wir konnten die Maschine nicht laden',
    categoriesLoadFail: 'Kategorien konnten nicht geladen werden',
    tooLargeRadius: 'Der Such-Radius ist zu gross',
    startdateGreaterThanEnddate: 'Das Enddatum liegt vor dem Startdatum',
    itemDelted: 'Maschine wurde gelöscht',
    employeesNotLoaded: 'Mitarbeitende konnten nicht geladen werden',
    employeeNotDeleted: 'Der Mitarbeitende konnte nicht von Ihrer Liste gelöscht werden',
    employeeNotAdded: 'Mitarbeitender konnte nicht hinzugefügt werden',
    imagesNotLoaded: 'Die Bilder konnten nicht geladen werden',
    imageNotUploaded: 'Wir konnten das Bild nicht hochladen',
    imageUploaded: 'Bild wurde hochgeladen',
    favoriteAdded: 'Diese Maschine ist nun in Ihrer Favoritenliste gespeichert',
    failFavoriteAdd: 'Leider konnten wir die Maschine nicht in Ihrer Favoritenliste speichern',
    favoriteRemoved: 'Wir haben die Maschine aus Ihren Favoriten entfernt',
    failFavoriteRemove: 'Leider konnte die Maschine aus Ihren Favoriten nicht entfernt werden'
  },
  spinner: {
    loadingReservationsOfTheYear: 'Wir laden das gewünschte Jahr',
    getPreviousCounter: 'Wir suchen nach dem vorherigen Zählerstand für Sie',
    loadingUserData: 'User Profil wird geladen',
    imageUpload: 'Bild wird hochgeladen. Einen Moment bitte.',
    pleaseWait: 'Bitte warten',
    login: 'Wir logen Sie ein.',
    dataLoading: 'Daten werden geladen.',
    search: 'Wir suchen für Sie',
    itemDelete: 'Maschine wird gelöscht',
    deleteEmployee: 'Mitarbeiter wird von Ihrer Liste entfernt',
    addEmployee: 'Mitarbeiter wird Ihrer Liste hinzugefügt',
    addFavorite: 'Maschine wird zu Ihren Favoriten hinzugefügt',
    removeFavorite: 'Wir entfernen die Maschine aus Ihren Favoriten',
    deleteImage: 'Wir löschen das Bild. Kurzen Moment bitte.'
  },
  multiselect: {
    noResult: 'Sorry. Nix gefunden.',
    selectLabel: 'hinzufügen',
    deselectLabel: 'abwählen',
    selectedLabel: 'ausgewählt'
  },
  pictureInput: {
    drag: 'Hier klicken, um Bild hochzuladen <br> oder Bild hier hineinziehen',
    plus: '+',
    upload: 'Bild hinzufügen'
  },
  _comment: {
    'last time sent for translation': 'unknown'
  },
  v2: {
    dayIsInPast: 'Sie können nicht in der Vergangenheit reservieren',
    invoiceMarked: 'Die Rechnung wurde als bezahlt markiert',
    invoiceNotMarked: 'Die Rechnung konnte leider nicht als bezahlt markiert werden',
    setPrices: 'Preise festlegen',
    assuranceLabel: 'Art der Versicherung eingeben',
    assurance: 'Maschine versichert?',
    manual: 'Anleitung (Manual)',
    itemInformations: 'Weitere Informationen',
    immediatelyReservation: 'Sofort mietbar?',
    noAssurance: 'Keine Versicherung angegeben',
    unlimitedMaxReservationTime: 'Keine Begrenzung',
    unlimitedMaxReservationDistance: 'Keine Begrenzung',
    maxReservationTimeLimitReached: 'Die Maschine hat eine maximale Reservationszeit',
    minimumSelectOneDay: 'Bitte wählen Sie mindestens einen Tag aus',
    maxReservationLimitExeeded: 'Sie überschreiten die maximale Reservationszeit',
    ofTotalAmount: 'von insgesamt {totalAmount} {unit} aufgeteilt',
    splitNow: 'Reservation aufteilen',
    splitReservationModalTitle: 'Reservation aufteilen?',
    reallySplitReservationModalText: 'Wollen Sie diese Reservation auf andere Landwirte aufteilen?',
    responsible: 'Verantwortlicher',
    responsibleCouldNotBeLoaded: 'Der Maschinenverantwortliche konnte nicht geladen werden',
    noItemsResponsibleFor: 'Sie haben keine Maschinen von Dritten, für die Sie verantwortlich sind.',
    responsibleMenu: 'Verantwortlich',
    myItemsMenu: 'Meine Maschinen',
    itemsMenu: 'Maschinen',
    responsibleWidgetHeader: 'Verantwortlicher Maschinen',
    autologinFailed: 'Wir konnten Sie leider nicht automatisch einloggen.',
    baseFee: 'Grundtarif pro Reservation (CHF)',
    baseFeeShort: 'Grundtarif',
    paid: 'Bezahlt?',
    counterCoefficient: 'Umrechnungsfaktor',
    counterState: 'Zählerstand',
    counterStatEquals: 'Zählerstand 1 entspricht',
    capture: 'Erfassung',
    toClose: 'Zum Abschliessen',
    conflicts: 'Konflikte',
    missingAmountTableHeader: 'Fehlbetrag',
    conflictingReservationsWidgetHeader: 'Zählerkonflikte',
    resetPwTitle: 'Passwort vergessen?',
    welcomeOnFarme: 'Willkommen bei FarmX',
    mail: 'E-Mail',
    password: 'Passwort',
    login: 'Login',
    mobileNumber: 'Handynummer',
    rent: 'Vermieten',
    lease: 'Mieten',
    clickedDayHasToBeLinked: 'Die ausgewählten Tage müssen zusammenhängen!',
    timeFor: 'Wählen Sie die Zeit für den',
    allDay: 'Ganztägig',
    higherTimeNotPossible: 'Sie haben die maximale Reservationszeit erreicht.',
    minDayTimeLimitReached: 'Sie können diese Zeit nicht unterschreiten',
    startReservationOn: 'Reservation beginnt am',
    endReservationOn: 'Reservation endet am',
    reservationTime: 'Reservationszeit: {days} Tage und {hours} Stunden',
    maxReservationTimeExeeded: 'Maximale Reservationszeit wird um {hours} Stunden überschritten!',
    isReservationRequestCorrect: 'Wollen Sie den neuen Reservationsvorschlag versenden?',
    notificationType: 'Benachrichtigungen per E-Mail',
    mailCode: 'Code aus dem E-Mail hier eingeben',
    setNewPassword: 'Setzen Sie ein neues Passwort',
    mailCodeExplain: 'Wir haben Ihnen eine E-Mail mit einem Sicherheits-Link geschickt. ' +
      'Öffnen Sie diesen, um ein neues Passwort zu setzen. Tipp: Schauen Sie auch in Ihren Spam-Mails nach, sollten' +
      'Sie keine Nachricht von uns erhalten haben.',
    pwAlreadyResetted: 'Passwort wurde schon zurückgesetzt! Email beachten.',
    mailOrMobileWrong: 'E-Mail oder Handynummer falsch',
    loginProblem: 'Login war nicht erfolgreich. Stellen Sie sicher, dass ihre Angaben richtig sind.',
    editCounterNotPossibleAlreadyBilled: 'Der Zähler kann nicht mehr verändert werden, weil die Reservation bereits verrechnet wurde.',
    commentHere: 'Nachricht hier eintippen',
    sendMessageToOwner: 'Senden Sie dem Besitzer eine Nachricht',
    senderWroteToReservation: 'schrieb zu dieser Reservation',
    messageToReservation: 'Nachricht vom Mieter',
    counterUnits: 'Zählereinheiten',
    setCounterForOtheruser: 'Sie sind dabei, für {firstName} {name} den Gebrauch zu erfassen',
    withinRadius: 'Umkreis',
    stayLoggedIn: 'Angemeldet bleiben',
    formNotComplete: 'Sie müssen alle Felder mit einem * ausfüllen',
    plzDoesNotExist: 'Diese PLZ existiert nicht. Bitte kontaktieren Sie office@seccom.ch',
    plzNotValid: 'Diese PLZ ist ungültig.'
  },
  v3: {
    unitsNotLoaded: 'Die Einheiten konnten nicht geladen werden!',
    yourFeedback: 'Geben Sie Ihr Feedback zu FarmX.',
    feedBackTitle: 'FarmX ist in der Testphase',
    foundABug: 'Haben Sie einen Fehler gefunden? Oder möchten Sie uns einen Verbesserungsvorschlag schicken? Wir sind ganz Ohr.',
    dontHesitate: 'Zögern Sie nicht, uns zu kontaktieren.',
    thanksForTheSupport: 'Besten Dank für Ihre Unterstützung!'
  },
  v4: {
    reservationOverview: 'Übersicht Vermieten',
    actualizeApp: 'App aktualisieren',
    reservationValidation: 'Eine Reservation muss von mir bestätigt werden',
    confirmedReservation: 'Bestätigt',
    unconfirmedReservation: 'Unbestätigt',
    hint: 'Hinweis',
    hintExplanaition: 'Die Reservationsanfrage kann nicht bestätigt werden. Es besteht bereits eine Reservation in der gewünschten Zeit.',
    reservationOverviewNotLoaded: 'Die Reservationsübersicht konnte nicht geladen werden.',
    noReservations: 'Keine Reservationen',
    showMore: 'Mehr anzeigen',
    setStartCounterModalTitle: 'Zähler Beginn erfassen',
    startCounterRequired: 'Sie müssen den Wert des Zählers eintragen.',
    startCounterCaptured: 'Zähler erfolgreich gespeichert.',
    extend: 'verlängern',
    countDownNotPossible: 'Die Verlängerungszeit muss mindestens eine halbe Stunde betragen (0.5).',
    countUpNotPossible: 'Sie können die Reservation nicht weiter verlängern.',
    hasNextReservation: '{user} hat die Maschine am {date} um {time} reserviert.',
    hasMaxTime: 'Diese Maschine kann maximal {time} Stunden gemietet werden.',
    extendNotPossible: 'Verlängerung nicht möglich',
    close: 'schliessen',
    moreDetails: 'Weitere Angaben',
    cantonalOperationNumber: 'Kantonale Betriebsnummer',
    startCounterOnlyExplanation: 'Erfassen Sie den Zählerstand vor dem Gebrauch der Maschine',
    changeReservation: 'Ändern',
    noManual: 'Keine Anleitung vorhanden',
    noPreviousRenter: 'Kein vorheriger Mieter',
    noFollowingRenter: 'Kein auf Sie folgender Mieter',
    locationNotFound: 'Adresse auf der Karte nicht gefunden.',
    editLocation: 'Standort auf der Karte anpassen (Funktion in Arbeit)',
    counterDiffer: 'Ihre Eingabe weicht vom Zählerstand der letzten Reservation ab.',
    today: 'heute',
    selectStartDate: 'Wählen Sie das Start-Datum',
    selectEndDate: 'Wählen Sie das End-Datum',
    reservations: 'Reservationen',
    blockMachine: 'Maschine sperren',
    reservationsCouldNotBeenLoaded: 'Reservationen konnten nicht geladen werden.',
    blockedByOwner: 'Gesperrt',
    hasReservationsInTheMiddle: 'Ihre Auswahl darf nicht durch Reservationen unterbrochen werden. Wir haben Ihre Auswahl angepasst.',
    doYouWantBlockTheItem: 'Möchten Sie die Maschine blockieren?',
    blockingTime: 'Sperrperiode: {days} Tage und {hours} Stunden',
    doYouWantToDeactivateItem: 'Maschine jetzt aktivieren?',
    activateExplanation: 'ist moment deaktiviert und kann nicht reserviert werden. Möchten Sie die Maschine jetzt aktivieren?',
    blockTime: 'Ausgewählte Zeit sperren',
    deactivateUndefined: 'Unbestimmte Zeit deaktivieren',
    activateItem: 'aktivieren',
    itemIsActuallyDeactivated: 'Die Maschine ist momentan deaktiviert.',
    deleteBlocking: 'Blockierung aufheben',
    deleteWholeBlockingPeriod: 'Die gesamte Blockierungszeit wird gelöscht',
    blockingDeleted: 'Blockierung wurde aufgehoben'
  },
  v5: {
    menuAdmin: 'Administrator',
    captureCustomer: 'Erfassen',
    eMailRequired: 'Bitte geben Sie eine E-Mail-Adresse ein',
    isNotEmail: 'Dies ist keine gültige E-Mail-Adresse',
    register: 'Registrieren',
    nameRequired: 'Bitte geben Sie einen Nachnamen ein',
    firstNameRequired: 'Bitte geben Sie einen Vornamen ein.',
    mailRequired: 'E-Mail *',
    firstNameFormRequired: 'Vorname *',
    nameFormRequired: 'Nachname *',
    addressRequired: 'Bitte geben Sie eine Adresse ein',
    addressFormRequired: 'Adresse *',
    zipRequired: 'Bitte geben Sie eine PLZ ein',
    zipFormRequired: 'PLZ *',
    cityRequired: 'Bitte geben Sie einen Ort ein',
    cityFormRequired: 'Ort *',
    pwRequired: 'Bitte geben Sie ein Passwort ein',
    setPassword1: 'Passwort *',
    setPassword2: 'Passwort wiederholen *',
    mobileRequired: 'Bitte geben Sie eine gültige Handy-Nummer ein.',
    mobileFormRequired: 'Handy-Nummer *',
    telForm: 'Festnetz',
    ibanForm: 'IBAN Bankkonto (für Rechnungen)',
    newUserSuccessfullyRegistered: 'Kunde wurde erfolgreich erfasst. Er kann sich nun einloggen und seine Handynummer bestätigen.',
    addNewCustomer: 'Neuen Kunden erfassen',
    selectLanguage: 'Sprache wählen *',
    languageRequired: 'Bitte wählen Sie eine Benutzersprache aus.',
    registration: 'Neu hier? Jetzt gratis registrieren!',
    resetPwHelpText: 'Haben Sie Ihr Passwort vergessen? Kein Problem! Geben Sie einfach Ihre E-Mail und Handynummer ein. Dann erhalten Sie von uns eine E-Mail mit weiteren Anweisungen.',
    newPassword: 'Neues Passwort',
    userAlreadyExist: 'Dieser Benutzer existiert bereits!',
    pwTooWeak: 'Das Passwort ist zu schwach!',
    invalidZip: 'Die PLZ existiert nicht!',
    reCaptchaNotValid: 'Es scheint, dass Sie versuchen, unsere Applikation auszutricksen.',
    registerSuccess: 'Erfolgreich registriert. Sie können sich jetzt anmelden.',
    wrongCredentials: 'E-Mail oder Passwort sind nicht korrekt.',
    userLocked: 'Ihr Benutzerkonto wurde gesperrt. Bitte wenden Sie sich an den Support.',
    mobileVerificationModalTitle: 'Bestätigen Sie Ihre Identität',
    mobileVerificationCodeRequired: 'Bitte geben Sie den SMS-Code ein.',
    mobileVerificationCodeFormLabel: 'SMS-Code hier eingeben',
    validateMobileNumber: 'überprüfen',
    wrongMobileToken: 'Falscher SMS-Code',
    checkMobileToken: 'Bitte überprüfen Sie den eingegebenen Code',
    addNewItem: 'Neue Maschine zum Vermieten ausschreiben',
    itemTitle: 'Name (z.B. John Deere 6410) *',
    itemTitleRequired: 'Bitte geben Sie einen Maschinennamen ein.',
    itemDescription: 'Beschreibung Maschine (z.B. 110 Ps, Fronthydraulik...) *',
    itemDescriptionRequired: 'Bitte Maschinenbeschreibung eingeben.',
    selectLabel: 'Kategorie wählen *',
    categoryRequired: 'Wählen Sie mindestens eine Kategorie',
    privateAmount: 'Preis für Mitglieder (CHF) *',
    privateAmountRequired: 'Preis für Mitglieder eingeben (CHF).',
    globalItemPriceRequired: 'Preis für Nicht-Mitglieder eingeben (CHF)',
    globalItemPrice: 'Preis für Nicht-Mitglieder (CHF) *',
    unitRequired: 'Einheit auswählen',
    selectVat: 'Mehrwertssteuersatz *',
    selectVatRequired: 'Wählen Sie einen Mehrwertsteuersatz',
    location: 'Standort',
    prices: 'Preise',
    options: 'Optionen',
    conflictsNotLoaded: 'Die Konflikte konnten nicht geladen werden',
    user: 'Benutzer',
    solveConflict: 'lösen',
    conflictInfo: 'Klicken Sie auf die Zähler-Werte, um sie zu korrigieren. Wenn Sie fertig sind, klicken Sie auf «lösen», um den Konflikt abzuschliessen.',
    countConflicts: '1 Konflikt | {count} Konflikte',
    reallySolveConflict: 'Die Zähler weisen einen Fehlbetrag auf!',
    reallySolveConflictText: 'Wollen Sie den Konflikt trotz Fehlbetrag als gelöst markieren?',
    enterAmount: 'Bitte wählen Sie den Steuersatz aus',
    descriptionRequired: 'Bitte geben Sie eine Beschreibung für die Rechnungsposition ein',
    selectDate: 'Datum auswählen',
    minFee: 'Minimalpreis pro Reservation (CHF)',
    cancellationPoliciesCouldNotBeenLoaded: 'Die Stornierungsbedingungen konnten nicht geladen werden. Bitte versuchen Sie es später nochmals.',
    vatPriceExplanation: 'Die Preise verstehen sich immer inklusive Mehrwertssteuern.'
  },
  v6: {
    minFeePerReservation: 'Min.Betrag/Reser.',
    cancellationPolicy: 'Stornierungsbedingungen',
    noMemberTarif: 'Nicht-Mitglieder',
    memberTarif: 'Mitglieder',
    minFeeTimeLeft: 'Sie können die Reservation noch rund {timeLeft} kostenlos stornieren.',
    minFeeHasToBePaid: 'Die Reservation kann nicht mehr gratis reserviert werden. Es wird ein Minimalbetrag von CHF {minFee} fällig.',
    minFeeShort: 'Min.Betrag',
    cancelledThe: 'Storniert am',
    totalVat: 'Total MwSt.',
    from: 'ab',
    customer: 'Kunden',
    vat: 'Mehrwertsteuern',
    editCustomer: 'Nutzer bearbeiten',
    supporCustomer: 'Support',
    editVat: 'Mehrwertsteuern',
    items: 'Maschinen',
    editUnits: 'Einheiten',
    titleRequired: 'Bitte geben Sie eine Anrede ein',
    firstNameFormRequired: 'Anrede *',
    pleaseConfirmMobile: 'Bitte bestätigen Sie Ihre Handynummer',
    farmxConfirmationReason: 'Auf FarmX können sich nur Personen registrieren, dessen Identität wir überprüfen können. Damit schützen wir Sie auf FarmX vor Personen mit betrügerischen Absichten.',
    enterSmsCode: 'Bitte geben Sie hier den Code ein, den wir Ihnen per SMS geschickt haben',
    mobileTokenResent: 'Code wurde versendet. Bitte haben Sie einen kurzen Moment Geduld.',
    mobileTockenResendingFailed: 'Code konnte nicht verschickt werden. Bitte überprüfen Sie Ihre Handy-Nummer',
    resendCode: 'SMS-Code neu senden',
    mobileCorrect: 'Stimmt die Handy-Nummer?',
    testThreeMonthForFree: 'Testen Sie FarmX unverbindlich 3 Monate lang gratis.',
    page: 'Seite',
    overview: 'Ubersicht',
    manageCustomers: 'Kunden verwalten',
    inputRequired: 'Eingabe benötigt',
    searchForUsers: 'Keine Personen gefunden. Im Suchfeld nach Vorname, Nachname, PLZ und/oder Ort suchen',
    customerSingular: 'Kunde',
    missingPermission: 'Fehlende Berechtigung'
  },
  v7: {
    signInAsCustomer: 'Als Kunde einloggen',
    signInReason: 'Login-Grund',
    loginReasonRequired: 'Sie müssen einen Grund für das Login angeben',
    ticketNumber: 'Ticket-Nummber',
    ticketNumberRequired: 'Sie müssen eine Ticket-Nummer für das Login eingeben',
    tooManyRequests: 'Zugriff verweigert: Sie haben sich zu oft als diesen User angemeldet.',
    loginAsCustomerSuccessfully: 'Erfolgreich als Kunde eingeloggt. Bearbeitung bitte in neuem Fenster fortführen.',
    copyUrlModalTitle: 'Url kopieren',
    urlCopiedSuccessfully: 'Url wurde in die Zwischenablage kopiert',
    copyLink: 'Kopieren Sie die URL in ein neues Browser-Fenster, um sich einzuloggen.',
    fifteenMinutes: 'Dieser Link ist <span class="font-weight-bold">15 min</span> gültig.'
  },
  v8: {
    toContinue: 'Um fortzufahren musst Du',
    closeWindow: 'Dieses Fenster schliessen',
    getNewLink: 'Im Admin-Bereich einen neuen Link für diesen Benutzer lösen.',
    securityReasons: 'Diese Massnahme ist aus Sicherheitsgründen notwendig.',
    clickRecaptcha: 'Bitte bestätigen Sie, dass Sie kein Roboter sind.',
    gtcCouldNotBeenLoaded: 'Die AGBs konnten leider nicht geladen werden. Versuchen Sie es später nochmals.',
    acceptGtc: 'Ich habe die AGBs und Cookie-Richtlinien von FarmX gelesen und stimme ihnen zu.',
    showAgb: 'lesen',
    acceptGtcRequired: 'Bitte stimmen Sie den AGBs von Farmx zu.',
    gtcModalTitle: 'AGBs FarmX',
    agreeGtc: 'zustimmen',
    rejectGtc: 'ablehnen',
    gtcCouldNotBeAccepted: 'Leider ist beim Akkzeptieren der AGB ein Fehler aufgetreten. Bitte versuche es später noch einmal.',
    welcomeToFarmX: 'Willkommen',
    setPassword: 'Passwort festlegen',
    why: 'weshalb',
    sendSmsCode: 'SMS-Code senden',
    downloadExcel: 'Laden Sie hier die Daten Ihrer Maschinen als Excel herunter',
    selectRadius: 'Radius unter 100 km eingeben',
    freeTestingTillEndOfJune: 'Für Mieter ist FarmX kostenlos',
    onlyForMembers: 'Maschine wird nur Mitgliedern vermietet.',
    siteIsLoading: 'Seite wird geladen',
    passwordHasNumberOrSpecialCharacter: 'Das Passwort muss mindestens eine Zahl oder ein Sonderzeichen enthalten.',
    hasSmallAndCapitalLetter: 'Das Passwort muss mindestens einen Gross- und einen Kleinbuchstaben enthalten.',
    tabUpcomingRent: 'Planung',
    tabAll: 'Archiv',
    selectDateToGetOverview: 'Wählen Sie einen Zeitraum aus, um eine Reservationsübersicht zu erhalten.',
    loginStatus: 'Status Registration',
    entireWordNeeded: 'Vollständiges Wort eingeben (Bsp. Bienne).',
    userAlreadyAdded: 'Der Benutzer wurde bereits zu Ihrer Liste hinzugefügt',
    userCouldNotBeenLoaded: 'Der Benutzer konnte nicht geladen werden.',
    senderHasWrittenNotification: '{sender} hat Ihnen am {date} um {time} eine Nachricht gesendet',
    endReservationProcess: 'Beenden'
  },
  v9: {
    overtake: 'Übernahme',
    itemReturn: 'Rückgabe',
    captureUse: 'Nutzung abschliessen',
    deviceRegisteredSuccessfully: 'Ihre App wurde erfolgreich für Pushs registriert.',
    deviceCouldNotBeRegistered: 'Beim Registrieren Ihrer App trat ein unbekannter Fehler auf.',
    appNotification: 'Push-Benachrichtigungen (Smartphone)',
    chooseYourPw: 'Bitte wählen Sie Ihr Passwort.',
    startTimeMustBeSmallerThanEndTime: 'Die Startzeit kann nicht grösser sein als die Endzeit.',
    endTimeMustBeBiggerThanStartTime: 'Die Endzeit kann nicht kleiner sein als die Startzeit',
    earlierNotPossible: 'Früher geht nicht.',
    laterNotPossible: 'Später geht nicht!',
    startBlockingPeriodOn: 'Beginn der Blockierung',
    endBlockingPeriodOn: 'Ende der Blockierung',
    blockingWasDeleted: 'Blockierung wurde aufgehoben',
    overlapingSelection: 'Sie können keine bereits reservierte oder blockierte Zeitspanne wählen. Bitte passen Sie Ihre Reservationszeit an.',
    generalDatabaseError: 'Es ist ein Problem mit der Datenbank aufgetreten. Bitte wenden Sie sich an den Support. info-de@farmx.ch',
    dataTooLongForColumn: 'Ein Datenfeld war zu gross. Bitte wenden Sie sich an den Support. info-de@farmx.ch',
    userTriesToEditSomeBodeyElsesReservation: 'Sie versuchen, eine Reservation zu bearbeiten, die nicht Ihnen gehört.',
    userNameDoesNotExist: 'Benutzername existiert nicht. Bitte wenden Sie sich an den Support. info-de@farmx.ch',
    updateFailed: 'Die Reservation konnte nicht aktualisiert werden. Bitte wenden Sie sich an den Support. info-de@farmx.ch',
    updatedInactiveFieldAsNonAdmin: 'Sie versuchen, eine Reservation einer gelöschten Maschine zu bearbeiten. Bitte wenden Sie sich an den Support. info-de@farmx.ch',
    timeLimitReached: 'Sie überschreiten das maximale Reservationslimit der Maschine. Bitte versuchen Sie es nochmals oder wenden Sie sich an den Support. info-de@farm.ch',
    triedToExtendReservation: 'Ein technischer Fehler ist aufgetreten. Bitte wenden Sie sich an den Support. info-de@farmx.ch',
    triedToUpdateAlreadyBilledReservation: 'Sie können keine Reservation bearbeiten, die bereits verrechnet wurde. Bitte wenden Sie sich an den Support. info-de@farmx.ch',
    validationNeededFirst: 'Die Reservation muss zuerst bestätigt werden. Bitte wenden Sie sich an den Besitzer oder den Verantwortlichen der Maschine.',
    reservationIsInPast: 'Sie können keine Reservationen in der Vergangenheit erstellen.',
    maxDistanceLimitExeeded: 'Die Maschine überschreitet die maximale Reservationsdistanz. Bitte wenden Sie sich an den Besitzer oder Verantwortlichen der Maschine.',
    waitForValidation: 'Die Reservation muss vom Besitzer/Verantwortlichen der Maschine noch bestätigt werden. Sie erhalten spätestens in 24 Stunden eine Rückmeldung.',
    tokenExpiredSentMail: 'Der Link ist leider abgelaufen. Wir haben Ihnen zur Sicherheit einen neuen Link an Ihre E-Mail-Adresse geschickt. Bitte prüfen Sie Ihre E-Mails.',
    tokenExpired: 'Der Link ist abgelaufen.'
  },
  v10: {
    bookNow: 'Jetzt reservieren',
    appStatisticsCouldNotBeLoaded: 'Die App-Statistiken konnten leider nicht geladen werden.',
    appStatistics: 'App-Statistiken',
    searchItems: 'Maschinen suchen',
    noInnercircleItems: 'Sie sind bei keinem Betrieb und keiner Genossenschaft Mitglied. Keine Maschinen vorhanden.',
    noFavoriteItems: 'Sie haben bisher noch keine Maschinen als Favoriten markiert.',
    addFirstItem: 'Stellen Sie jetzt Ihre erste Maschine auf FarmX online. Es ist ganz einfach.',
    captureNow: 'Jetzt erfassen',
    noAdvancedSearchResults: 'Ihre Suche ergab leider keinen Treffer.',
    helpToImproveFarmX: 'Möchten Sie stattdessen eine Ihrer Maschinen besser auslasten?',
    onlineNow: 'Jetzt online stellen',
    useRequired: 'Bitte tragen Sie die Nutzung ein.',
    completeForm: 'Bitte alle Felder ausfüllen',
    itWas: 'Dieser betrug',
    reservationEditNotPossible: 'Reservation wurde kostenlos storniert. Bearbeitung nicht möglich.',
    use: 'Nutzung',
    defineLocation: 'Standort festlegen',
    searchForAddress: 'Nach Adresse suchen',
    addressNotFoundSelectManually: 'Die Adresse konnte nicht gefunden werden. Klicken Sie in die Karte, um Ihren Standort einzuzeichnen.',
    setUserLocation: 'Legen Sie Ihren Standort fest',
    itemTooFarAway: 'Die Maschine ist zu weit entfernt.',
    all: 'Alle Kategorien',
    away: 'entfernt',
    occupiedTo: 'Bereits zu {percent} % besetzt',
    moreSearchFields: 'Mehr Suchfelder',
    yourMailContainsAWhiteSpace: 'Ihre E-Mail enthält ungültige Leerzeichen.',
    coordinatesRequired: 'Ungültiger Standort',
    newAppVersionAvailable: 'Neue App Version verfügbar',
    actualizeAppNow: 'Es ist eine neue App-Version verfügbar. Bitte jetzt aktualisieren.',
    versionNumber: 'Neue App-Version',
    deadApiModalTitle: 'Unsere Server stehen derzeit unter Last',
    weSolveTheProblem: 'Wir bitten Sie um einen kurzen Moment Geduld. Wir arbeiten daran, dass FarmX so rasch als möglich wieder funktioniert. Bitte versuchen Sie es in 5 Minuten erneut.',
    gotThat: 'Verstanden'
  },
  systemNotification: {
    checkItemsTitle: 'Standort Ihrer Maschinen überprüfen',
    checkItemsText: 'FarmX hat ein neues Koordinatensystem eingeführt, um den Standort Ihrer Maschine punktgenau erfassen zu können. Bitte überprüfen Sie die Standorte Ihrer Maschinen.'
  },
  v11: {
    userBilling: 'Export Abo-Rechnungen',
    downloadWinbizCsv: 'CSV für Winbiz',
    debit: 'Debitorenkonto',
    credit: 'Konto',
    debitRequired: 'Debitorenkonto angeben',
    creditRequired: 'Konto angeben',
    howToSelectWinBizDates: 'Datum immer über ein ganzes Jahr wählen. Z.B. vom 01.01.2019 bis und mit 01.01.2020',
    invoiceDateRequired: 'Rechnungsdatum eingeben',
    invoiceDate: 'Rechnungsdatum',
    allReservations: 'Alle Reservationen',
    app: 'App',
    technicalInformations: 'Technische Informationen',
    appInformations: 'App-Informationen',
    userId: 'Benutzer-ID',
    appVersion: 'Version FarmX (Front- und Backend)',
    nativeAppDeviceId: 'Device-ID (Android / IOS)',
    hasDevice: 'Wurde eine App erkannt (Android / IOS)?',
    allRegisteredDeviceIds: 'Registrierte Device-IDs (API)',
    isDeviceIdFoundInRegisteredIds: 'Wird Device-ID in den registrierten Device-IDs gefunden (Funktion):',
    allCookies: 'Alle Cookies',
    lastStep: 'Fast geschafft!',
    acceptConditions: 'Um die Maschine zu veröffentlichen, akzeptieren Sie folgende Bedingungen',
    generalFees: 'Wenn Sie diese Maschine veröffentlichen, ist dies zunächst kostenlos. Wenn Sie Ihre Maschine jedoch mindestens einmal im laufenden Rechnungsjahr vermieten, verrechnen wir dies mit <span class="font-weight-bold"> {costsPerItem} chf / Maschine.</span>',
    weSendABill: 'Zum jetztigen Zeitpunkt müssen Sie sich nicht um die Bezahlung kümmern. Wir senden Ihnen Ende Jahr gegebenenfalls eine Rechnung zu.',
    benefitFromDiscounts: 'Profitieren Sie von Rabatten',
    tillFiveItems: 'Bis 5 vermietete Maschinen: {costsPerItem} chf / Maschine und Jahr',
    moreThanFiveItems: 'Mehr als 5 vermietete Maschinen: {discountPerItem} % Rabatt / Maschine und Jahr',
    moreThanFiftyItems: 'Mehr als {maxDiscount} vermietete Maschinen: {maxDiscount} % Rabatt / Maschine und Jahr',
    gtcApplyByTheWay: 'Im Übrigen gelten die Allgemeinen Geschäftsbedingungen von FarmX GmbH.',
    counterCoefficientRequired: 'Bitte Zähler-Koeffizient eingeben',
    publishItemNow: 'Maschine ausschreiben'
  },
  v12: {
    myFarm: 'Mein Betrieb',
    editAddress: 'Adresse bearbeiten',
    onMyInvoices: 'Rechnungen',
    paymentWithin: 'Zahlbar innert',
    days: 'Tagen',
    daysMandatory: 'Bitte geben Sie eine Zahlungsfrist ein',
    tenDaysMin: 'Zahlungsfrist muss mindestens 10 Tage betragen',
    isCancelled: 'Storniert',
    captureUse: 'Nutzung erfassen',
    splitReservation: 'Nutzung Dritten verrechnen',
    isCaptured: 'Nutzung erfolgreich erfasst',
    workingHours: 'Arbeitsstunden',
    hourlyWage: 'Stundenlohn',
    remove: 'Entfernen',
    myStandardHourlyWage: 'Mein Stundenlohn',
    selectAddressee: 'Wählen Sie einen Adressaten',
    createInvoices: 'Rechnungen generieren',
    invoicesNotLoaded: 'Die Rechnungen konnten leider nicht gelaen werden.',
    invoiceCouldNotBeCreated: 'Die Rechnung konnte leider nicht erzeugt werden',
    vatForItems: 'Mehrwertssteuersatz für Maschinen *',
    vatForWork: 'Mehrwertsteuersatz für Arbeit *',
    showVatSection: 'Mehrwertsteuern hinzufügen',
    workingHoursWith: 'Arbeitszeit mit',
    splitMarkFailed: 'Reservation konnte nicht als aufgeteilt markiert werden',
    invoicesSuccessfullyGenerated: 'Die Rechnungen wurden erfolgreich generiert.',
    alreadySplit: 'Bereits weiterverrechnet',
    splitAnyway: 'Erneut weiterverrechnen',
    alreadySplitInformation: 'Sie haben diese Reservation bereits einmal weiterverrechnet.',
    howDoYouLikeToContinue: 'Wie möchten Sie fortfahren?',
    invoicesPayableWithin: 'Rechnungen zahlbar innert',
    downloadAllUsers: 'Alle',
    menu: 'Menü',
    logout: 'Logout',
    loggingYouOut: 'Wir melden Sie ab...',
    phoneOnlyFees: 'Anzeigen für Maschinen, die nur telefonisch gemietet werden können, werden von FarmX so verrechnet, als wären sie mindestens einmal vermietet worden.',
    taxId: 'MwSt-Nummer'
  },
  v13: {
    discounts: 'Tarifabstufungen',
    discount: 'Tarifabstufung',
    createDiscount: 'Neue Tarifabstufung erfassen',
    discountsLoadFailed: 'Tarifabstufungen konnten nicht geladen werden',
    discountLoadFailed: 'Tarifabstufungen konnte nicht geladen werden',
    description: 'Bezeichnung',
    targetDate: 'Stichdatum (jährliche Wiederholung)',
    duration: 'Zeitdauer',
    unit: 'Einheit',
    machines: 'Maschinen',
    discountLevels: 'Tarifstufen',
    edit: 'Bearbeiten',
    newDiscount: 'Erfassen',
    updateFailed: 'Update fehlgeschlagen',
    addingFailed: 'Erstellen fehlgeschlagen',
    addDiscountLevel: 'Tarifstufe hinzufügen',
    discountForm: {
      descriptionEmptyError: 'Bezeichnung muss mindestens 3 Zeichen lang sein',
      descriptionLabel: 'Geben Sie der Tarifabstufung eine Bezeichnung',
      machinesLabel: 'Welche Maschinen sollen der Tarifabstufung zugeordnet werden?',
      levelStart: 'Ab',
      levelEnd: 'Bis',
      levelPrice: 'Reduktion',
      save: 'Speichern',
      others: 'Andere',
      discountAdded: 'Tarifabstufung erstellt',
      askForDeletion: 'Wollen Sie die Tarifabstufung wirklich löschen?',
      deletionAcceptance: 'Löschen',
      abort: 'Abbrechen',
      accept: 'Löschen',
      dateNotSet: 'Stichdatum fehlt',
      mustSetDate: 'Sie müssen ein Stichdatum angeben',
      noMachineSelected: 'Keine Maschine ausgewählt',
      mustSelectMachine: 'Sie müssen mindestens eine Maschine auswählen',
      negativeValuesNotAllowed: 'Negative Werte sind nicht erlaubt',
      discountGreaterThan: 'Muss grösser als 0 sein',
      UpperMustBeGreaterThanLower: 'Muss grösser sein als Startmenge',
      mustEqualToProceeding: 'Muss der vorangehenden Endmenge entsprechen',
      required: 'Benötigt',
    },
    maintenance: 'Wartung',
    maintenanceLowerThreshold: 'Status Orange ab',
    maintenanceLowerThresholdRequired: 'Der Wert von Rot muss grösser sein als Orange',
    maintenanceUpperThresholdRequired: 'Der Wert von Orange muss grösser sein als Orange',
    maintenanceUpperThreshold: 'Status Rot ab',
    resetMaintenance: 'Wartungstatus zurücksetzen',
    resetMaintenanceBtn: 'zurücksetzen',
    resetMaintenanceDesc: 'Setzen Sie den Wartugsstand zurück. Wählen Sie dabei das Datum der zuletzt erfolgten Wartung.',
    trafficLightLoadFailed: 'Ampel-Daten konnten nicht geladen werden',
    lastMaintenanceDate: 'Letzte Wartung',
    month: 'Monate',
    itemToDiscount: 'Ausgewählt',
    responsibleCompensation: 'Halterentschädigung',
    startDate: 'Startdatum',
    endDate: 'Enddatum',
    reservationRejected: 'Reservation wurde abgelehnt. Bearbeitung nicht möglich.',
    rejectedReservation: 'abgelehnt',
    chfPerUnit: 'CHF / Einheit',
    customFieldsWereNotUpdated: 'Benutzerdefinierte Felder konnten nicht gespeichert werden.',
    customFieldsWereNotCreated: 'Benutzerdefinierte Felder konnten nicht erstellt werden.',
    withDiscount: 'Tarifabstufung',
    discountFetchingError: 'Beim Abrufen der Tarifabstufungen ist ein Fehler aufgetreten',
    youMayGetDiscount: 'Wenn Sie diese Maschine mieten, gewährt Ihnen der Vermieter ab einer bestimmten Nutzung eine Tarifreduktion.',
    errorWhileSignalFetching: 'Beim Abrufen der Serviceanzeige ist ein Fehler aufgetreten.',
    downloadExcel: 'Excel herunteralden',
    downloadCompensationExcel: 'Halterentschädigung herunterladen',
    timeSpan: 'Zeitraum',
    compensationPerUnit: 'Entschädigung / Einheit',
    totalCompensation: 'Total Entschädigung',
    sendInvoicesManually: 'Rechnungen NICHT per E-Mail versenden.',
    sendInvoicesAutomatically: 'Rechnungen bei Bestätigung automatisch versenden (empfohlen).',
    export: 'export',
    noBillings: 'Keine bestätigte Abrechnungen',
    noCustomInvoices: 'Keine bestätigten Einzelrechnungen',
    noIncomingInvoices: 'Sie haben bisher keine Rechnungen erhalten',
    invoiceId: 'Rechnungs-Id',
    invoices: 'Rechnungen',
    rejectedThe: 'abgelehnt',
    state: 'Status',
    send: 'Versenden',
    reallySend: 'Sie sind im Begriff, die Rechnungen an alle Empfänger per E-Mail zu versenden. <b>Dies kann nicht rückgängig gemacht werden!</b>',
    sendInvoiceViaMail: 'Rechnung per Mail versenden!',
    errorOnCalculatingDiscounts: 'Beim Berechnen der Tarifabstufungen ist ein Fehler aufgetreten!',
    alreadyBilled: 'Nutzung bisher',
    useForBillingPeriode: 'Nutzung aktuell',
    totalUseInBillingPeriode: 'Nutzung total',
    subTotal: 'Subtotal',
    showPrices: 'Preise anzeigen',
    contact: 'Kontakt',
    itemLocation: 'Standort Maschine',
    awayIn: 'entfernt in',
    mailConfirmationRequired: 'Bitte geben Sie Ihre E-Mail erneut ein.',
    mailConfirmation: 'E-Mail wiederholen *',
    mailsDiffer: 'Kontrollieren Sie die E-Mail. Die Eingaben unterscheiden sich.',
    allInputsAreRequired: '* Alle Angaben mit einem Stern (*) sind obligatorisch.',
    customId: 'Interne Maschinennummer (wird nicht publiziert)',
    internalNumber: 'Interne Nummer',
    autoAuthentication: 'Authentifizierung läuft...',
    lastResetDate: 'Zuletzt zurückgesetzt'
  },
  invitation: {
    shareFarmX: 'FarmX teilen',
    inviteMember: 'Mitglieder einladen',
    linkCopied: 'Link wurde kopiert. Sie können ihn jetzt teilen - Zum Beispiel via Whatsapp oder auf den Sozialen Medien.',
    copyLink: 'Link',
    multipleTimesValid: 'Mehrmals gültig',
    singleValid: 'Einmalig gültig',
    code: 'Code',
    generateLink: 'Link generieren',
    onlyCharAndNumbersAllowed: 'Es sind nur Zahlen und Buchstaben erlaubt.',
    min4Chars: 'Der Code muss mindestens 4 Zeichen lang sein.',
    link: 'Link',
    expireDate: 'Ablaufdatum',
    codeTooShort: 'Der eingegebene Code ist zu kurz. Er muss mindestens 4 Zeichen lang sein.',
    unsupportedChars: 'Die Code enthält ungültige Zeichen. Es sind nur Zahlen und Buchstaben erlaubt.',
    unknownMode: 'Technischer Fehler: Unbekannter Modus.',
    codeAlreadyUsed: 'Der eingegebene Code wird im System bereits verwendet. Bitte versuche es nocheinmal mit einem neuen Code.',
    codeSuccessfullyGenerated: 'Der Link wurde erfolgreich generiert. Sie können ihn jetzt von der Liste unten kopieren und teilen.',
    deleteLink: 'Sollen wir den Link wirklich löschen? Dies kann nicht rückgängig gemacht werden.',
    linkDeleted: 'Wir haben den Link gelöscht.',
    overwriteLinkQuestion: 'Bestehenden Link überschreiben?',
    overwriteLink: 'überschreiben',
    linkWillBeOverwritten: 'Der folgende Link wird überschrieben und ersetzt. Möchten Sie fortfahren?',
    linkToBeOverwritten: 'Dieser Link wird überschrieben',
    welcomeToFarmX: 'Schön, dass Sie da sind!',
    invitedBy: 'Sie wurden von {invitor} zu FarmX eingeladen. Bitte wählen Sie das weitere Vorgehen aus.',
    invitedByAnRegisterNow: 'Sie wurden von {invitor} zu FarmX eingeladen. Jetzt nur noch kostenlos registrieren.',
    invitationReceived: 'Haben Sie einen Einladungscode erhalten?',
    codeInvalid: 'Der eingegebene Code ist ungültig.',
    alreadyLogin: 'Ich habe bereits ein Konto.',
    newToFarmX: 'Ich bin neu. Gratis registrieren.',
    addedAsMember: 'Sie wurden erfolgreich als Mitglied von {invitor} hinzugefügt.',
    copyCode: 'Code kopieren',
    codeCopied: 'Code wurde kopiert. Sie können ihn jetzt einer Person zustellen, die noch keinen FarmX-Account hat.',
    invitePeopleToFarmx: 'Personen zu FarmX einladen',
    createLinkTitle: 'Link generieren'
  },
  dataTable: {
    noDataAvailable: 'Keine Einträge'
  },
  info: {
    incomingInvoices: 'Auf dieser Seite sehen Sie alle Rechnungen, die Sie von anderen FarmX-Benutzer erhalten haben. Nur der Absender kann eine Rechnung als bezahlt markieren. <br><br>' +
      'Klicken Sie auf eine Rechnung, um sie als PDF herunterzuladen oder den Link zum PDF zu kopieren.',
    simpleLink: 'Kopieren Sie den Link im Bereich «FarmX teilen». Schicken Sie den kopierten Link Kunden, Kollegen und Freunde, um sie auf FarmX einzuladen.' +
      'Sie können sich so ganz einfach auf FarmX registrieren.',
    memberInvitation: 'Erstellen Sie im Bereich «Mitglieder einladen» einen Link, den Sie Personen zustellen, um sie automatisch als Mitglied hinzuzufügen. Sie haben dabei folgende ' +
      'Möglichkeiten zur Auswahl: <br><br> <span class="">Code:</span> Wenn Sie dieses Feld leer lassen, erstellt das System automatisch einen zufällig generierten Link. Sie können aber auch' +
      ' selbst einen Code eingeben, mit dem der Link erstellt wird. <br><br> <span class="">Einmalig gültig:</span> Mit dieser Auswahl kann der generierte Link nur einmalig verwendet werden.' +
      ' Sie sollten ihn also nur einer Person zukommen lassen. <br><br> <span class="">Mehrmals gültig: </span> Der Link kann mehrmals verwendet werden. Sie können den Link also mehreren Personen zustellen.'
  },
  state: {
    confirmed: 'Bestätigt',
    draft: 'Entwurf',
    sent: 'Versandt'
  },
  registrationForm: {
    'enterCode': 'Code eingeben'
  },
  sMap: {
    searchItemText: 'Nach Maschine suchen',
    checkCoordinates: 'Koordinaten prüfen'
  },
  itemMap: {
    zipNotFound: 'PLZ nicht gefunden.',
    priceOnRequest: 'Preis auf Anfrage',
    selectCategory: 'Kategorie wählen'
  },
  navigationBar: {
    home: 'Home',
    about: 'Über'
  },
  pwReset: {
    pwReset: 'Passwort zurücksetzen'
  },
  userLocationModal: {
    longitude: 'Längegrad',
    latitude: 'Breitengrad'
  },
  mySettings: {
    changeMail: 'Mailadresse ändern',
    newMail: 'Neue Mailadresse',
    changePersonalData: "Daten ändern"
  },
  v14: {
    archiveRentState: 'Status',
    cancelled: 'Storniert'
  },
  v15: {
    canEditMachine: 'Verantwortlicher darf Stammdaten bearbeiten.',
    usageNotRecorded: 'Gebrauch nicht erfasst',
    employerHasNoMachine: 'Keine Maschinen publiziert.',
    replaceList: 'Filter anwenden',
    resetFilters: 'Filter zurücksetzen',
    filterByMachine: 'Maschinen filtern',
    activeFilters: 'Aktive Filter',
    reportDamage: 'Vorhandenen Schaden melden',
    isReportingDamage: 'Schadensmeldung',
    sendImage: 'Foto hinzufügen',
    messageRequired: 'Geben Sie eine Nachricht ein.',
    damageProofIsRequired: 'Fügen Sie ein Foto als Beweis für den Schaden bei',
    message: 'Nachricht',
    messageSubject: 'Betreff',
    messageText: 'Inhalt der Nachricht',
    attachedImage: 'Angehängtes Foto',
    messageNotSuccessful: 'Die Nachricht konnte nicht versendet werden!',
    messageSuccessful: 'Nachricht versendet.',
    tooLateForMessage:'Es können keine Nachrichten für Reservationen älter als 4 Tage erfasst werden.',
    commentCannotBeEmpty: 'eine Nachricht eingeben oder ein Foto senden',
    membersValidation: 'Bestätigung auch für Mitglieder erforderlich',
    showPrivateMachines: 'Private Maschinen anzeigen',
    filterDamageReports: 'Nach Schadensmeldung filtern',
    showDamageReport:'Schadensmeldung anzeigen',
    searchByMachineOrId: 'Nach Name oder interner Nummer suchen',
    customizeMenu: 'Menü personalisieren',
    uploadImage:'Bilder hochladen',
    noCommentsTitle:'keine Nachrichten',
    noCommentsSubTitle:'zur Zeit liegen keine Meldungen über diese Reservierung vor',
    comments:'Nachrichten',
  },
  archiveExport: {
    renterIsMember: 'Mitglied',
    noData: 'Daten nicht vorhanden'
  },
  socialMediaLinks: {
    subject: 'FarmX',
    body: 'Schau Dir diese Maschine an'
  },
  itemPublicDetails: {
    siteTitle: 'FarmX - Einfach mieten',
    description: 'Optimieren Sie Ihre Maschinenkosten mit FarmX: mieten, reservieren, planen, abrechnen.'
  },
  itemCreateView: {
    switchToAdvanced: 'Mehr Einstellungen',
    switchToEasy: 'Weniger Einstellungen'
  },
  imageUploader: {
    mainImageMissing: 'Kein Hauptbild vorhanden. Jetzt hochladen.',
    noFileAvailable: 'Keine Datei ausgewählt',
    fileTypeNotSupported: 'Dateityp nicht unterstützt. Laden Sie eine Bilddatei hoch.',
    fileReaderApiNotSupported: 'Ein unerwarteter Fehler ist aufgetreten: FileReader-API nicht unterstützt. Bitte wenden Sie sich an den FarmX-Support.',
    mainImage: 'Hauptbild',
    replaceImage: 'Anderes Bild hochladen',
    disclosureTitle: 'Deutliche Offenlegung - Prominent Disclosure',
    disclosureIntroduction: 'FarmX erhebt/überträgt/synchronisiert/speichert Bilder, um',
    disclosureItemImages: 'Ihre Maschinen den Mietern auf eine nützliche und attraktive Art und Weise zu präsentieren. Damit erhalten die Mieter einen guten Eindruck der Maschine, die sie mieten möchten.',
    disclosureUserImage: 'Ihr Firmen-Logo auf Ihrem Profil anderen FarmX-Nutzern anzuzeigen und Ihre Maschinen damit zu kennzeichnen (zu branden).',
    disclosureGtcLink: 'Privacy Policy und Terms & Conditions von FarmX',
    disclosureMoreInformation: 'Mehr Informationen zu Google Prominent Disclosure'
  },
  itemNotReservable: {
    notReservable: 'Leider kann die gewünschte Maschine nicht reserviert werden. Grund:',
    hint: 'Verwenden Sie die Erweiterte Suche, um ein passendes Gerät zu finden.',
    itemInactive: 'Die Maschine wurde vom Besitzer deaktiviert.',
    distance: 'Die Maschine ist zu weit von Ihnen entfernt. Der Besitzer hat die maximale Vermietdistanz limitiert.'
  },
  userEditInvoiceModal: {
    invalidIban: 'Ungültige IBAN'
  },
  captureIbanModal: {
    title: 'Bitte erfassen Sie Ihre IBAN',
    enterIban: 'IBAN Ihres Kontos eingeben',
    explanationText: 'Damit wir gültige Rechnungen generieren können, geben Sie bitte die IBAN Ihres Kontos ein.'
  },
  openReservations: {
    noReservations: 'Alles bestens. Sie haben keine abzuschliessenden Reservationen.'
  },
  itemsServices: {
    variantCreationFailed: 'Die Maschinen-Variante konnte nicht erstellt werden.'
  },
  itemGridEditable: {
    okClone: 'Variante erstellen',
    cloneVariantTitle: 'Variante erstellen?',
    cloneVariantText: 'Möchten Sie die aktuelle Maschine als Variante vermieten? ' +
      'Dies ist beispielsweise nützlich, wenn Sie ein und die selbe Maschine mit unterschiedlichen Konditionen vermieten möchten. ' +
      'Stellen Sie sicher, dass Sie den Titel und die Beschreibung aussagekräftig anpassen, damit Ihre Mieter wissen, welche Variante ' +
      'der Maschine sie reservieren sollten.',
    variant: 'Variante',
    cloneNotPossibleTitle: 'Variante erstellen nicht möglich',
    cloneNotPossibleText: 'Diese Maschine hat einen Zähler. Deshalb kann keine Maschinen-Variante erstellt werden, da dies zu Zählerkonflikten führen würde.'
  },
  itemEditableActions: {
    editItem: 'Maschine bearbeiten',
    blockItem: 'Maschine sperren oder deaktivieren',
    createVariant: 'Maschinen-Variante erstellen',
    deleteItem: 'Maschine löschen'
  },
  testimonialsServices: {
    getTestimonialsFailed: 'Testimonials konnten nicht geladen werden.'
  },
  testimonialsSlider: {
    rentNow: 'Jetzt Maschine vermieten',
    doNotShowUpAgain: 'Nicht mehr anzeigen'
  },
  translations: {
    missingTranslation: 'Übersetzung fehlt',
    missingTranslationShort: 'Ü.F.',
    missingTranslationDescription: 'Übersetzung in der Datenbank nicht gefunden.'
  },
  discount: {
    targetDateMissing: 'Unerwarteter Fehler: Das Stichdatum fehlt (Discount.loadDiscountReservations())!'
  },
  selectMonth: {
    dataNotLoaded: 'Daten waren nicht vollständig geladen. Bitte versuch es nochmals.',
    overlappingTargetDate: 'Die Abrechnungsperiode darf das Stichdatum keiner Tarifabstufung überlappen.'
  },
  appInformation: {
    localStorage: 'Lokaler Speicher'
  },
  membersService: {
    newMemberRequestFailed: 'Fehler: Person konnte nicht für Mitgliedschaft angefragt werden.',
    couldNotDeleteMember: 'Mitglied konnte nicht gelöscht werden.'
  },
  members: {
    memberExplanation: 'Personen oder Organisationen, die bei mir Mitglied sind.',
    members: 'Mitglieder',
    requests: 'Ausstehende Anfragen',
    customNotificationTitle: 'Push-Nachrichten an meine Mitglieder',
    createCustomNotification: 'Neue Nachricht schreiben.'
  },
  memberships: {
    memberships: 'Mitgliedschaften',
    explanation: 'Personen oder Organisationen, bei denen ich Mitglied bin.',
    requests: 'Offene Anfragen'
  },
  membershipRequests: {
    acceptMembershipModalTitle: 'Wollen Sie die Mitgliedschaft annehmen?',
    acceptTextI: 'Wenn Sie Mitglied bei {lessor} werden, können Sie möglicherweise von besseren Mietkonditionen ' +
      'profitieren. Auch werden Ihnen die Maschinen von {lessor} beim Reservieren direkt angezeigt.',
    acceptTextII: 'Sie können {lessor} das Recht gewähren, Ihnen Rundschreiben als Push-Nachrichten zu senden. Sie können dieses Recht jederzeit widerrufen.',
    allowNotifications: '{lessor} darf mir Push-Nachrichten senden.',
    denyNotifications: '{lessor} darf mir keine Push-Nachrichten senden.',
    denyModelTitle: 'Mitgliedschaft wirklich ablehnen?',
    denyModalText: 'In einer Mitgliedschaft können Sie in der Regel von günstigeren Mietkonditionen profitieren. Wollen Sie die Anfrage dennoch ablehnen?',
    denyModalOkText: 'Mitgliedschaft ablehnen',
    action: 'Aktionen',
    missingCurrentMembershipRequest: 'Unerwarteter Fehler: Missing current membership request'
  },
  memberList: {
    member: 'Name, Ort',
    receivesCustomNotifications: 'Erhält meine Nachrichten',
    doesntReceiveCustomNotifications: 'Erhält keine Nachrichten',
    deleteMember: 'Mitglied entfernen',
  },
  membershipsService: {
    cancelledMembership: 'Mitgliedschaft erfolgreich beendet.',
    saved: 'Änderung gespeichert',
    acceptedMembershipRequest: 'Sie sind jetzt Mitglied.',
    deniedMembershipRequest: 'Sie haben die Mitgliedschaft abgelehnt.'
  },
  membershipList: {
    receiveNotifications: 'Benachrichtigungen erlaubt',
    bannedNotifications: 'Benachrichtigungen gestoppt',
    cancelMembershipBtn: 'Mitgliedschaft beenden',
    cancelMembershipTitle: 'Mitgliedschaft beenden?',
    cancelMembershipText: 'Wenn Sie die Migliedschaft beenden, können Sie möglicherweise die Maschinen dieses Vermieters nicht mehr mieten oder die Tarife ändern sich.'
  },
  invitationAnswerModal: {
    mayLessorSendYouMessages: 'Nachrichten von {lessor} erlauben?',
    acceptInvitation: 'Erlauben',
    cancelInvitation: 'Nicht erlauben'
  },
  customNotificationService: {
    couldNotCreate: 'Nachricht konnte nicht erstellt werden.',
    couldNotRetrieve: 'Nachricht konnte nicht geladen werden.',
    couldNotRetrieveNotifications: 'Nachrichten konnten nicht abgerufen werden.',
    created: 'Nachricht wurde gespeichert.',
    saved: 'Gespeichert',
    couldNotSave: 'Speichern fehlgeschlagen.',
    testSent: 'Test-Nachricht an Ihr Gerät verschickt.',
    couldNotSendTest: 'Test-Nachricht konnte nicht verschickt werden.',
    couldNotSend: 'Nachricht konnte nicht verschickt werden.',
    sent: 'Nachricht erfolgreich verschickt.'
  },
  customNotificationForm: {
    createCardTitle: 'Neue Nachricht an meine Mitglieder',
    updateCardTitle: 'Nachricht bearbeiten',
    missingBodyText: 'Bitte Nachricht verfassen.',
    cancel: 'Abbrechen',
    createNew: 'Speichern',
    selectLanguage: 'Sprache wählen',
    selectLanguageRules: 'Sie müssen eine Sprache wählen.',
    pushTitle: 'Push-Titel',
    pushTitleRules: 'Sie müssen einen Titel für die Push-Nachricht eingeben.',
    notificationTitle: 'Titel der Nachricht',
    notificationTitleRules: 'Sie müssen einen Titel für die Nachricht eingeben.',
    save: 'speichern',
    sendTest: 'Test-Versand',
    send: 'versenden',
    sendBtnTooltip: 'Nachricht meinen Mitgliedern versenden.',
    sendTestTooltip: 'Testweise die Nachricht auf mein Gerät schicken (Mitglieder erhalten keine Nachricht).',
    logTitle: 'Protokoll',
    testSendDateLog: 'Letzter Testversand',
    sendDateLog: 'Versand',
    createdAtLog: 'Erstellt am',
    updatedAtLog: 'Zuletzt bearbeitet am',
    noTestSend: 'Kein Testversand',
    noSend: 'Kein Versand',
    sendResponseTitle: 'Erfolgreicher Versand',
    sentToUserWithLanguage: 'Die Push-Nachricht wurde an Ihre Mitglieder verschickt, welche die Sprache "{language}" in Ihrem Farmx-Profil ausgewählt haben.',
    pushDeviceCount: 'Geräte',
    userCount: 'Mitglieder',
    sendTestFirst: 'Testversand erforderlich',
    sendOkText: 'Versenden',
    beforeSendModalTitle: 'Push-Nachricht jetzt verschicken?',
    beforeSendModalText: 'All Ihre Mitglieder mit der Sprache "{language}" werden von Ihnen eine Push-Nachricht erhalten. Wollen Sie die Nachricht jetzt verschicken?',
    customNotificationTestSent: 'Die Push-Nachricht wurde an Ihr Gerät verschickt. | Die Push-Nachricht wurde an Ihre Geräte verschickt.'
  },
  customNotificationList: {
    sendDate: 'Am {sendDate} verschickt',
    notSent: 'Nicht verschickt'
  },
  notificationService: {
    notificationPatchFailed: 'Die Nachricht konnte nicht als (un)gelesen markiert werden.',
    couldNotRetrieveNotification: 'Die Nachricht konnte nicht geladen werden.'
  },
  customNotificationView: {
    sender: 'Absender der Nachricht',
    sendDate: 'Datum des Versands'
  },
  editorLinkBtn: {
    noLinkSet: 'Bitte einen Link mit https:// eingeben.',
    addLink: 'Link hinzufügen'
  },
  connectedItemsWarningModal: {
    close: 'Schliessen',
    title: 'Kombinierte Maschine auch anpassen?',
    body: 'Diese Maschine ist mit weiteren Maschinen kombiniert. Möchten Sie diese auch anpassen?'
  },
  itemDetail: {
    isVariantOf: 'Diese Maschine kann in <span class="text-decoration-underline">anderer Ausführung</span> reserviert werden.',
    hasVariants: 'Diese Maschine kann in <b>anderer Ausführung</b> reserviert werden.'
  },
  itemsGridDescription: {
    selectVariant: 'Ausführung wählen'
  },
  reservationCloseView: {
    captureUsageTitle: 'Nutzung erfassen',
    captureUsageDescription: 'Erfassen Sie die Nutzung der Maschine'
  }
}
