<template>
  <v-row
    class="fill-height align-end my-0"
    style="min-height: 8.7em;"
  >
    <!-- title -->
    <v-col class="col-12 pb-0">
      <div
        class="font-weight-black"
        style="overflow: hidden; line-height: 1.3em; max-height: 3em;"
      >
        {{ item.getName() }}
      </div>
    </v-col>

    <!-- description -->
    <v-col class="col-12 py-0">
      <div
        class="mb-0 body-2"
        style="max-height: 2.9em; overflow: hidden;"
      >
        {{ item.getDescription() }}
      </div>
    </v-col>

    <!-- reservation button -->
    <v-col
      class="col-12 pl-3 pr-1 pt-2 pb-2"
    >
      <!-- phone only button -->
      <v-btn
        v-if="!hideReservationBtn && item.isTelOnly()"
        color="blue"
        @click.capture.stop="$refs.telOnlyInformation.openTelOnlyModal(item)"
        outlined
        small
        class="mr-3"
      >
        {{$t('reservation.telOnly')}}
      </v-btn>

      <!-- reservation button -->
      <v-btn
        v-if="!hideReservationBtn && !item.isTelOnly()"
        @click.capture.stop="$emit('selectItem')"
        :disabled="!item.canISeeTheMachine(false, true)"
        outlined
        small
        height="36px"
        color="primary"
        class="mr-3"
        style="white-space: normal;"
      >
        <div
          v-if="item.hasVariants()"
          :style="{fontSize: $vuetify.breakpoint.smAndDown ? '10px' : 'inherit'}"
        >
          {{$t('itemsGridDescription.selectVariant')}}
        </div>
        <span v-else>
          {{$t('reservation.reservation')}}
        </span>
      </v-btn>
    </v-col>

    <!-- tel only component -->
    <tel-only-information
      ref="telOnlyInformation"
    />
  </v-row>
</template>

<script>
import Item from '../../../classes/Item'
import TelOnlyInformation from '../TelOnlyInformation'

export default {
  name: 'ItemsGridDescription',
  components: {TelOnlyInformation},
  props: {
    item: {
      type: Item,
      required: true
    },
    hideReservationBtn: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>
