import Vue from 'vue'
import Item from './Item'
import Vat from './Vat'
import moment from 'moment-timezone'
import config from '../config'
import CancellationPolicy from './CancellationPolicy'
import User from './User'
import axios from "axios"
import router from "@/router/router";
import ReservationComment from './ReservationComment'
import Reservations from '@/classes/Reservations'

export default class Reservation {
  constructor (reservation, user = null) {
    this.id = reservation.id
    this.startDate = reservation.startdate
    this.endDate = reservation.enddate
    this.user = user
    this.startCounter = reservation.hasOwnProperty('startCounter') ? reservation.startCounter : null
    this.endCounter = reservation.hasOwnProperty('endCounter') ? reservation.endCounter : null
    this.counterCoefficient = reservation.hasOwnProperty('counterCoefficient') ? reservation.counterCoefficient : null
    this.amount = reservation.hasOwnProperty('amount') ? reservation.amount : null

    this.minFee = reservation.hasOwnProperty('minFee') ? reservation.minFee : null
    this.price = reservation.hasOwnProperty('price') ? reservation.price : null

    this.split = reservation.hasOwnProperty('alreadySplit') ? reservation.alreadySplit : null

    // if reservation was cancelled before time and don't cost anything
    this.toBill = reservation.hasOwnProperty('toBill') ? reservation.toBill : null

    this.damageNotice = reservation.damageNotice ? reservation.damageNotice : null

    // Type: ReservationComment
    // Chats about a reservation
    this.reservationComments = this.parseReservationComment(reservation.reservationsComments)

    // storno time of the reservation
    this.cancellationTime = reservation.hasOwnProperty('cancellationTime') ? reservation.cancellationTime : null

    // reservation requests
    this.validationStep = reservation.hasOwnProperty('validationStep') ? reservation.validationStep : null

    // Type: Item
    this.item = reservation.Item ? new Item(reservation.Item) : null

    // Type: Vat
    this.vat = reservation.vatType ? new Vat(reservation.vatType.id, reservation.vatType.vatRates, reservation.vatType.translations) : null

    // Type: CancellationPolicy
    this.cancellationPolicy = reservation.itemCancelPolicy ? new CancellationPolicy(reservation.itemCancelPolicy) : null

    // active = false => eg. a reservation that has been rejected by the owner
    this.active = reservation.hasOwnProperty('active') ? reservation.active : null

    // responsible user at the time of the reservaion
    this.responsible = reservation.ResponsibleUser ? new User(reservation.ResponsibleUser) : new User({})

    // was reservation made by a member?
    this.tenantIsMember = reservation.hasOwnProperty('tenantIsMember') ? reservation.tenantIsMember : null

    // surrounding reservations
    this.previousReservation = null
    this.nextReservation = null

    // todo: "canBeConfirmed" should be extracted in extended class ReservationRequest => Problem: async constructors cannot be extended
    // canBeConfirmed only comes with reservation requests endpoint
    this.canBeConfirmed = !!reservation.canBeConfirmed

    // related reservations
    this.parentReservationId = reservation.parentReservationId || null
    this.connectedReservations = reservation.connectedReservations ? new Reservations({}).parseApiReservations(reservation.connectedReservations) : new Reservations({}).parseApiReservations([])
  }

  /**
   * SETTERS
   */

  /**
   * set new amount
   * @param startCounter
   * @param endCounter
   */
  async setAmount (startCounter = this.startCounter, endCounter = this.endCounter, amount = this.amount) {
    // FIX: added parseFloat()
    this.startCounter = parseFloat(startCounter)
    this.endCounter = parseFloat(endCounter)
    this.amount = parseFloat(amount)

    return this.updateAmount(false)
  }

  async solveConflict () {
    return this.updateAmount(true)
  }

  parseReservationComment(rawReservationComments = []) {
    if (!rawReservationComments || !rawReservationComments.length) return []
    const reservationComments = rawReservationComments.map((rawReservationComment) => {
      return new ReservationComment(rawReservationComment)
    })
    return reservationComments
  }

  /**
   * update amount of reservation
   *
   * @param solveConflict
   */
  async updateAmount (solveConflict) {
    /* global EventBus api i18n */
    EventBus.$emit('spinnerShow')

    try {
      let response = await axios.patch(api + 'reservation/' + this.id + '/setAmount', {
        amount: this.amount,
        startCounter: this.startCounter,
        endCounter: this.endCounter,
        solveConflict: solveConflict
      })

      if (response.status === 200) {
        Vue.notify({
          title: i18n.t('notify.userUpdated'),
          type: 'success'
        })

        return true
      } else {
        Vue.notify({
          title: i18n.t('notify.userUpdateFailed'),
          text: response.status,
          type: 'error'
        })

        return false
      }
    } catch (e) {
      Vue.notify({
        title: i18n.t('notify.userUpdateFailed'),
        type: 'error'
      })

      return false
    } finally {
      EventBus.$emit('spinnerHide')
      EventBus.$emit('Reservation:UpdatedAmount')
    }
  }

  async setReservationSplit () {
    /* global api i18n */
    try {
      let response = await axios.patch(api + 'reservation/split/' + this.id)
      if (response.status !== 200) {
        Vue.notify({
          title: i18n.t('v12.splitMarkFailed'),
          text: response.status,
          type: 'error'
        })
      }
    } catch (e) {
      Vue.notify({
        title: i18n.t('v12.splitMarkFailed'),
        type: 'error'
      })
    }
  }

  /**
   * Reservation storno
   * @return {Promise<void>}
   */
  async cancel () {
    /* global EventBus api */
    EventBus.$emit('spinnerShow')

    try {
      await axios.patch(api + 'reservation/' + this.id, {
        active: false
      })
      Vue.notify({
        title: i18n.t('notify.reservationDeleted'),
        type: 'success'
      })
      return Promise.resolve()
    } catch (e) {
      Vue.notify({
        title: i18n.t('notify.error'),
        text: e.response.status,
        type: 'error'
      })
      return Promise.reject()
    } finally {
      EventBus.$emit('spinnerHide')
    }
  }

  /**
   * Storno methods
   */

  // was this reservation cancelled?
  isStorno () {
    return this.cancellationTime
  }

  getStornoDate () {
    if (this.isStorno()) {
      return moment.tz(this.cancellationTime * 1000, config.timezone).format('DD.MM.YY HH:mm')
    } else {
      return null
    }
  }

  // was this reservation rejected?
  rejected () {
    return this.cancellationTime === null && this.active === false
  }


  /**
   * GETTERS
   */
  // is the renter waiting for a reservation confirmation?
  isUnconfirmed () {
    return this.getValidationStep() !== null
  }

  canIeditReservation () {
    return this.getItem() && !this.getItem().needValidation() && !this.isUnconfirmed() && !this.isCaptured() && this.isMyReservation()
  }

  // is it my own reservation ?
  isMyReservation () {
    /* global store */
    return this.getUser().getUid() === store.state.appUserInstance.getRealUser().getId()
  }

  /**
   * Was the use of this reservation captured?
   * @returns {string|*|boolean|null}
   */
  isCaptured (item = this.getItem()) {
    // FIX 21.06.2019 => must compare with null => counter and amount can be 0 which is a falsy value
    return !!((item.hasCounter && this.getStartCounter() !== null && this.getEndCounter() !== null) || (!item.hasCounter && this.amount !== null))
  }

  /**
   * has the reservation be split
   * @returns {*|null}
   */
  isSplit () {
    return this.split
  }

  getId () {
    return this.id
  }

  getUser () {
    return this.user
  }

  getReservationComments () {
    return this.reservationComments
  }

  /**
   * Used to export comments on this reservation in an Excel
   * @return {string}
   */
  getReservationCommentsAsString () {
    let reservationCommentsString = ''
    const reservationComments = this.getReservationComments()
    for (let i = 0; i < reservationComments.length; i++) {
      const reservationComment = reservationComments[i]
      reservationCommentsString += reservationComment.getCommentAsString()
      // last comment
      if ((i + 1) < reservationComments.length) {
        reservationCommentsString += ' | '
      }
    }
    return reservationCommentsString
  }

  getDamageNotice () {
    return this.damageNotice
  }

  getResponsible () {
    return this.responsible
  }

  getPreviousReservation () {
    return this.previousReservation
  }

  getNextReservation () {
    return this.nextReservation
  }

  /**
   * Dates
   */
  // return start date in a formatted way
  getFormattedStartDate () {
    return moment.tz(this.startDate * 1000, config.timezone).format('DD.MM.YYYY')
  }

  getFormattedStartTime () {
    return moment.tz(this.startDate * 1000, config.timezone).format('HH:mm')
  }

  getFormattedEndTime () {
    return moment.tz((this.endDate * 1000) - (60 * 1000), config.timezone).format('HH:mm')
  }

  getFormattedEndDate () {
    return moment.tz((this.endDate * 1000) - (60 * 1000), config.timezone).format('DD.MM.YYYY')
  }

  // formatted reservation date
  getDate (showTime = true) {
    /* global store */
    return store.getters.getReservationDate(this.startDate * 1000, this.endDate * 1000, showTime)
  }

  getStartDate () {
    return this.startDate
  }

  getEndDate () {
    return this.endDate
  }

  // calc diff between end and start date in seconds
  getReservationTime () {
    return this.getEndDate() - this.getStartDate()
  }

  getOccupation (start, end) {
    start = this.startDate > start ? this.startDate : start
    end = end > this.endDate ? this.endDate : end

    return end - start
  }


  /**
   * Price calculations
   */

  getVat () {
    return this.vat
  }

  getItem () {
    return this.item
  }

  getMinFee () {
    return this.minFee
  }

  getPrice () {
    return this.price
  }

  /**
   * get the counter coefficient
   * @returns {*}
   */
  getCounterCoefficient () {
    return this.counterCoefficient
  }

  hasCounterCoefficient () {
    return this.counterCoefficient !== 1
  }

  /**
   * get total amount
   */
  getAmount () {
    // is the reservation to bill (free storno)
    if (this.toBill === false) {
      return 0
    } else { // to bill
      // 18.05.2019: added parse float
      return parseFloat(this.amount ? this.amount : parseFloat((this.endCounter - this.startCounter) * this.counterCoefficient).toFixed(3))
    }
  }

  /**
   * effective cost of the reservation
   * including
   * - to bill flag
   * - base fee
   * - counter or not
   * - counter coefficient
   */

  getSaldoUnrounded () {
    // if the flag "toBill" exist, handle it
    if (this.toBill === false) {
      return 0
    } else {
      let realSaldo = parseFloat(this.getAmount()) * parseFloat(this.getPrice())

      if (realSaldo > this.getMinFee()) return parseFloat(realSaldo)
      else {
        // has min fee?
        if (this.getMinFee()) return parseFloat(this.getMinFee())
        else return parseFloat(realSaldo) // custom entry with minus price
      }
    }
  }

  /**
   * get saldo but rounded by store function
   * @returns {Float}
   */
  getSaldo () {
    /* global store */
    return store.getters.roundCHF(this.getSaldoUnrounded())
  }

  // calc saldo without vat
  getSaldoExklVat () {
    // unrounded salod - vat
    return parseFloat(this.getSaldoUnrounded()) - this.getVatPrice()
  }

  /**
   * Methods to get vat rates at the time of the reservation
   */

  // calculated vat amount of reservation
  getVatPrice () {
    // do not calculate minus vat amount
    // FIX 26.09.2019: Don't care about negative values
    let saldoUnrounded = this.getSaldoUnrounded()

    // get correct rate at the time of the reservation
    // FIX 12.08.2019 => saldo equals 107.7 % or 102.5 % and not 100 %.
    return parseFloat(saldoUnrounded - (100 * parseFloat(saldoUnrounded) / parseFloat((this.getVatRate().rate + 100))))
  }

  // get the  correct vat rate at the time of reservation (start date)
  getVatRate () {
    return this.getVat().getRate(this.getStartDate() * 1000)
  }

  // get the vat name (with the correct rate inside) at the time of the reservation
  getVatName (showStartDate = false) {
    return this.getVat().getName(this.getStartDate() * 1000, showStartDate)
  }

  getStartCounter () {
    return this.startCounter
  }

  getEndCounter () {
    return this.endCounter
  }

  getValidationStep () {
    return this.validationStep
  }

  // Type: CancellationPolicy
  getCancellationPolicy () {
    return this.cancellationPolicy
  }


  /**
   * formatted json of the reservation instance
   * example of use: download as excel or display in table
   */
  getJson (itemInstance, showItem = false) {
    /** global store **/
    let lessor = store.state.appUserInstance.getRealUser()
    let jsonObject = {
      userName: this.getUser().getDisplayUser(false),
      userAddress: this.getUser().getAddress(),
      userZip: this.getUser().getZip(),
      userCity: this.getUser().getCity(),
      userMail: this.getUser().getMail(),
      userMobile: this.getUser().getMobile(),
      userLanguage: this.getUser().getLanguage().getLanguage(),
      tenantIsMember: this.getTenantIsMember(),
      fromDate: this.getFormattedStartDate(),
      fromTime: this.getFormattedStartTime(),
      toDate: this.getFormattedEndDate(),
      toTime: this.getFormattedEndTime(),
      counterFrom: this.getStartCounter(),
      counterTo: this.getEndCounter(),
      counterCoefficient: this.getCounterCoefficient(),
      amount: this.getAmount(),
      unit: itemInstance.getUnitShort(),
      price: this.getPrice(),
      currencyPerUnit: 'CHF' + ' / ' + itemInstance.getUnitShort(),
      minFee: this.getMinFee(),
      currencyMinFee: 'CHF',
      saldo: this.getSaldo(),
      currencySaldo: 'CHF',
      storno: this.getStornoDate(),
      rejected: this.rejected() ? i18n.t('v13.rejectedReservation') : '',
      comment: this.getReservationCommentsAsString(),
      lessorName: lessor.getDisplayUser(false),
      lessorAddress: lessor.getAddress(),
      lessorZip: lessor.getZip(),
      lessorCity: lessor.getCity(),
      lessorMail: lessor.getMail(),
      lessorMobile: lessor.getMobile()
    }

    // eventually add item name
    if (showItem) return {
      ...{
        itemCustomId: itemInstance.getCustomId(),
        itemName: itemInstance.getName(), ...jsonObject
      }
    }
    else return jsonObject
  }

  /**
   * Helper function
   * returns custom sentences depending if "tenantIsMember"  column is set in the database
   * true returns "yes"
   * false returns "no"
   * any other (null/undefined) returns "no data available"
   */
  getTenantIsMember () {
    if (typeof this.tenantIsMember === "boolean") {
      return this.tenantIsMember ? i18n.t('general.yes') : i18n.t('general.no')
    }
    return i18n.t('archiveExport.noData')
  }

  static routeToReservation (reservationID) {
    router.push({name: 'SingleReservationView', params: {id: reservationID}})
  }
}
