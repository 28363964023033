import router from '../router/router'
import User from './User'
import Reservations from './Reservations'
import Location from './Location'
import Vue from 'vue'
import BlockPeriodes from './BlockPeriodes/BlockPeriodes'
import CustomFields from './CustomFields/CustomFields'
import definitions from '../../definitions'
import ItemImages from './Images/ItemImages'
import Categories from './Categories/Categories'
import config from '../config'
import Items from './Items'
import Unit from './Units/Unit'
import NumberHelper from './Helper/NumberHelper'

/**
 * class parses api item object and provides some functions
 */

export default class Item {
  constructor (apiItem) {
    this.id = apiItem.id

    // owner
    // Type: User
    if (apiItem.hasOwnProperty('ItemOwner')) this.owner = new User(apiItem.ItemOwner)
    else this.owner = apiItem.hasOwnProperty('uid') ? new User({id: apiItem.uid}) : null

    // parse responsible uid
    this.responsible = null
    if (apiItem.hasOwnProperty('responsibleUid')) this.responsible = new User({id: apiItem.responsibleUid})
    if (apiItem.hasOwnProperty('responsibleUser')) this.responsible = new User(apiItem.responsibleUser)
    // responsible can edit the item
    apiItem.hasOwnProperty('responsibleCanEdit') ? this.responsibleCanEdit = apiItem.responsibleCanEdit : this.responsibleCanEdit = null

    // member
    // am I in the inner circle of the item's owner?
    this.member = apiItem?.innercircleUid || apiItem?.variantsParent?.member || null
    this.privateAmount = apiItem.hasOwnProperty('privamount') ? apiItem.privamount : null
    this.publicAmount = apiItem.hasOwnProperty('pubamount') ? apiItem.pubamount : null
    this.public = apiItem.public && apiItem.public === true

    // minimal amount to pay per reservation
    this.minFee = apiItem.hasOwnProperty('minFee') ? apiItem.minFee : null

    this.unit = apiItem.ItemUnit ? new Unit(apiItem.ItemUnit) : new Unit()
    if (apiItem.unit) this.unit.id = apiItem.unit // set unit id in unit instance

    // DEPRECATED => instead use "this.unit.id"
    this.unitId = apiItem.unit ? apiItem.unit : null
    this.name = apiItem.name ? apiItem.name : null

    // DEPRECATED => instead use "this.itemImages.getMainImage()"
    this.img = apiItem.img ? apiItem.img : null
    // Type: ItemImages
    this.itemImages = this.parseItemImages(apiItem.ItemImages)
    this.address = apiItem.address ? apiItem.address : null
    this.zip = apiItem.plz || apiItem.zip || null
    this.city = apiItem.ort || apiItem.city || null
    this.description = apiItem.description ? apiItem.description : null
    this.favorite = apiItem.Favorites && apiItem.Favorites.length > 0
    this.telOnly = !!(apiItem.telOnly && apiItem.telOnly === true)
    this.active = apiItem.active && apiItem.active === true
    this.itemCategories = this.parseItemCategories(apiItem.CategoriesItems)
    // DEPRECATED => instead use "this.itemCategories"
    this.categories = apiItem.CategoriesItems ? apiItem.CategoriesItems : null
    this.manual = apiItem.manual ? apiItem.manual : ''
    // defining standard cancellation policy as 1
    this.cancellationPolicy = apiItem.cancellationPolicy ? apiItem.cancellationPolicy : 1
    this.vat = apiItem.vat ? apiItem.vat : config.standardVatId
    this.counterCoefficient = apiItem.counterCoefficient ? apiItem.counterCoefficient : null
    this.insurance = apiItem.insurance ? apiItem.insurance : null
    this.maxDistance = apiItem.MaxDistance ? apiItem.MaxDistance / 1000 : null
    this.maxTime = apiItem.MaxReserveTime ? apiItem.MaxReserveTime / (60 * 60) : null
    this.reservationValidation = apiItem.ReservationValidation ? apiItem.ReservationValidation : false
    this.membersValidation = !!(apiItem.membersValidation !== null ? apiItem.membersValidation : true)
    this.hasCounter = apiItem.billingMethod && apiItem.billingMethod === 1
    this.customId = apiItem.hasOwnProperty('customId') ? apiItem.customId : null
    // Type: Items
    this.connectedItems = new Items({})
    // Type: Items | null
    this.variants = apiItem.variants ? this.parseVariants(apiItem.variants) : null
    this.variantsParentId = apiItem.isVariantOf || null
    this.variantsParent = apiItem.variantsParent || null

    // Type: Reservations
    this.reservations = apiItem.reservations ? new Reservations({apiReservations: apiItem.reservations}) : null
    // Type: BlockPeriodes
    this.blockPeriodes = null

    // parse block periodes
    if (apiItem.itemBlockePeriodes && apiItem.itemBlockePeriodes.length) {
      this.blockPeriodes = new BlockPeriodes()

      // parse block periodes
      this.blockPeriodes.parseAPI(apiItem.itemBlockePeriodes)
    }
    this.distance = apiItem.hasOwnProperty('distance') ? apiItem.distance / 1000 : null

    // Type: Location
    this.location = new Location(
      (apiItem.coordinatesLongitude ? parseFloat(apiItem.coordinatesLongitude) / 1000000 : null),
      (apiItem.coordinatesLatitude ? parseFloat(apiItem.coordinatesLatitude) / 1000000 : null),
      (apiItem.pseudoCoordinatesLongitude ? parseFloat(apiItem.pseudoCoordinatesLongitude) / 1000000 : null),
      (apiItem.pseudoCoordinatesLatitude ? parseFloat(apiItem.pseudoCoordinatesLatitude) / 1000000 : null)
    )

    // is item deleted
    this.deleted = apiItem.hasOwnProperty('deleted') ? apiItem.deleted : null

    /**
     * MODULES
     */
    // custom fields
    this.customFields = new CustomFields()
  }

  /**
   * HELPER
   */
  parseItemImages (itemImages) {
    let mainImage
    if (this.img) {
      mainImage = {link: this.img, isMainImage: true}
    }
    // item has multiple images
    if (itemImages) {
      // also add main image to the array
      if (mainImage) {
        itemImages = [mainImage, ...itemImages]
      }
      return new ItemImages().parseApi(itemImages)
    }
    // item has only main image
    if (mainImage) {
      return new ItemImages().parseApi([mainImage])
    }
    return new ItemImages()
  }

  parseItemCategories (itemCategories) {
    if (itemCategories) {
      const flattenCategories = itemCategories.map(itemCategory => {
        return {...itemCategory.Category, id: itemCategory.kid}
      })
      return new Categories().parseApiData(flattenCategories)
    }
    return new Categories()
  }

  parseVariants (apiItems) {
    if (!apiItems || !apiItems.length) {
      return null
    }
    let items = new Items({})
    items.parseApiItems(apiItems, {variantsParent: this})
    return items
  }

  // route to item detail view
  goToItem () {
    /* global store */
    router.push({name: 'ItemDetail', params: {id: this.id}})
  }

  async goToItemPublicPage () {
    await router.push({name: 'ItemPublicView', params: {id: this.id}})
  }

  /**
   * SETTERS
   */
  setId (id) {
    this.id = id

    // set id in each custom field
    let customFields = this.customFields.getCustomFields()
    for (let i = 0; i < customFields.length; i++) {
      customFields[i].setId(id)
    }
  }

  setFavorite (favorite) {
    this.favorite = favorite
  }

  // CUSTOM FIELD SETTER
  resetCustomFields () {
    this.customFields = new CustomFields()
  }

  setCustomField (key, value) {
    this.customFields.setCustomField(key, value, definitions.customFields.types.item, this.getId())
  }

  // save custom fields
  async saveCustomFields () {
    await this.customFields.save()
  }

  /**
   * GETTERS
   */
  getId () {
    return this.id
  }

  // return prices depending on owning
  getPrice () {
    /* global store */
    if (store.state.appUserInstance) {
      if (this.isMyMachine()) {
        return {
          public: this.publicAmount,
          private: this.privateAmount
        }
      } else if (this.amIResponsible()) { // I'm the responsible user
        return {
          public: this.publicAmount,
          private: this.privateAmount
        }
      } else if (this.member) { // I'm a member
        return {
          public: null,
          private: this.privateAmount
        }
      }
    }
    // return public price
    return {
      public: this.publicAmount,
      private: null
    }

  }

  // am I allowed to see the item details?
  // ignore distance e.g. for variants where we don't have the calculated distance available
  canISeeTheMachine (notify = false, ignoreDistance = false) {
    return this.canIEdit() || (this.memberOrPublic(notify) && (this.isMachineNearEnough() || ignoreDistance))
  }

  isMachineNearEnough (notify = false) {
    if (!this.getMaxDistance() || this.getMaxDistance() >= this.getDistance()) return true
    else { // item has max distance and item is too far away
      if (notify) {
        /* global i18n */
        Vue.notify({
          title: i18n.t('v10.itemTooFarAway'),
          type: 'error'
        })
      }
      return false
    }
  }

  memberOrPublic (notify = false) {
    // am I member or is the item public?
    if (this.public || this.member) return true
    else {
      // notify user
      if (notify) {
        Vue.notify({
          title: i18n.t('v8.onlyForMembers'),
          type: 'error'
        })
      }

      return false
    }
  }

  // has the active user the permission to edit the item?
  canIEdit () {
    if (this.amIEmployee()) return false
    return this.isMyMachine() || this.amIResponsible() && this.responsibleCanEdit
  }

  isMyMachine () {
    /* global store */
    return !!this.owner && !!store.state.appUserInstance && this.owner.getId() === store.state.appUserInstance.getId()
  }

  // is the current user responsible for the item?
  amIResponsible () {
    /* global store */
    return this.responsible && this.responsible.getId() === store.state.appUserInstance.getRealUser().getId()
  }

  amIEmployee () {
    /* global store */
    return store.state.appUserInstance.hasOtherUser()
  }

  // has the item a flag set, which requires a manual validation step by the owner or responsible
  needValidation () {
    return this.reservationValidation
  }

  // does the item need validation and I'm not a member nor can I edit the machine (owner or responsible)
  doIHaveToWaitForValidation () {
    return this.reservationValidation && !this.canIEdit() && (!this.member || this.member && this.membersValidation)
  }

  getMinFee () {
    return this.minFee
  }

  /**
   * Type: Unit
   * @return {Unit}
   */
  getUnit () {
    return this.unit
  }

  /**
   *
   * @param assumeCounterCoefficient: returns a generic unit name, if counter has a countercoefficient
   * @returns {VueI18n.TranslateResult|*|Function}
   */
  getUnitName (assumeCounterCoefficient = false) {
    /* global i18n store */
    if (this.unit) {
      if (assumeCounterCoefficient && this.hasCounterCoefficient()) return i18n.t('v2.counterState')
      return this.unit.getName().getName()
    }
    return null
  }

  /**
   *
   * @param assumeCounterCoefficient: returns a generic unit name, if counter has a countercoefficient
   * @returns {VueI18n.TranslateResult|*|Function}
   */
  getUnitShort (assumeCounterCoefficient = false) {
    /* global i18n store */
    if (assumeCounterCoefficient && this.hasCounterCoefficient()) return i18n.t('v2.counterState')
    return this.unit.getName().getShort()
  }

  getName () {
    return this.name
  }

  /**
   * name of item which is file save and xlsx save (max 30 chars)
   */
  getSaveName (id) {
    let nameWithId = id + '_' + this.getName()
    if (nameWithId.length > 29) {
      nameWithId = nameWithId.substring(0, 22) + '...' + nameWithId.substring(nameWithId.length - 4)
    }
    // eslint-disable-next-line no-useless-escape
    nameWithId = nameWithId.replace(/[/?*\[\]]/g, '')
    return nameWithId
  }

  getReservations () {
    return this.reservations
  }

  // does the item has reservations or blocking periodes?
  hasBlockingPeriodesOrReservations () {
    return (this.blockPeriodes && this.blockPeriodes.count() > 0) || (this.reservations && this.reservations.count() > 0)
  }

  occupiedRatio (start, end) {
    let totalTime = end - start
    let occupiedTime = 0

    // subtract block periodes
    occupiedTime += this.blockPeriodes ? this.blockPeriodes.getOccupation(start, end) : 0

    occupiedTime += this.reservations ? this.reservations.getOccupation(start, end) : 0

    return Math.round(occupiedTime * 100 / totalTime)
  }

  hasImg () {
    return this.img
  }

  getItemImages () {
    return this.itemImages
  }

  /**
   * alias of getItemImages
   * @return {ItemImages}
   */
  getImages () {
    return this.getItemImages()
  }

  /**
   * DEPRECATED in favor of getItemImages()
   * @param thumbnail
   * @return {string|*}
   */
  getImg (thumbnail = true) {
    /* global imageApi */
    if (thumbnail && this.img) {
      return imageApi + this.img + '_thumbnail.jpg'
    } else if (this.img) {
      return imageApi + this.img + '.jpg'
    } else return require('@/assets/imgs/placeholder.svg')
  }

  getZip () {
    return this.zip
  }

  getCity () {
    return this.city
  }

  getDescription () {
    return this.description
  }

  isFavorite () {
    return this.favorite
  }

  isTelOnly () {
    return this.telOnly === true
  }

  isActive () {
    return this.active
  }

  getOwner () {
    return this.owner
  }

  getAddress () {
    return this.address
  }

  getItemCategories () {
    return this.itemCategories
  }

  // DEPRECATED in favor of ^getItemCategories()^
  getCategories () {
    return this.categories
  }

  getPrivateAmount () {
    return this.privateAmount
  }

  getPublicAmount () {
    return this.publicAmount
  }

  getResponsible () {
    return this.responsible
  }

  getManual () {
    return this.manual
  }

  getLocation () {
    return this.location
  }

  hasValidLocation () {
    return this.location &&
      this.location.hasCoordinates() &&
      this.address && this.address !== '' &&
      this.zip && this.zip !== '' &&
      this.city && this.city !== ''
  }

  // in hours
  getMaxTime () {
    return this.maxTime
  }

  // in km
  getMaxDistance () {
    return this.maxDistance
  }

  getDistance () {
    let tmpDistance = this.distance
    return Math.round(tmpDistance * 10) / 10
  }

  hasCounterCoefficient () {
    return this.hasCounter && this.counterCoefficient !== 1
  }

  // returns responsible if present. if not, return owner
  getResponsibleOrOwner () {
    return this.getResponsible() ? this.getResponsible() : this.getOwner()
  }

  getCustomId () {
    return this.customId
  }

  hasVariants () {
    return this.variants && this.variants.getItems().length
  }

  getVariantItemsArray () {
    return this.variants ? this.variants.items : []
  }

  isVariantOf () {
    return (!!this.variantsParent || !!this.variantsParentId)
  }

  /**
   * whether the item is marked as deleted or not
   * @returns {*|null}
   */
  isDeleted () {
    return this.deleted
  }

  // summary of item in json
  async getJson () {
    return {
      customId: this.getCustomId(),
      name: this.getName(),
      startCounter: await this.getReservations().getStartCounterOfFirstReservation(),
      endCounter: await this.getReservations().getEndCounterOfLastReservation(),
      totalAmount: this.getReservations().getTotalAmount(),
      unit: this.getUnitShort(),
      totalExklVat: this.getReservations().getTotalExklVatRounded(),
      currencyTotalExklVat: 'CHF',
      totalVat: this.getReservations().getTotalVat(),
      currencyTotalVat: 'CHF',
      totalInklVat: this.getReservations().getTotal(),
      currencyTotalInklVat: 'CHF'
    }
  }

  /**
   * Parses an item instance to an api compatible json object which can be sent to api and will be accepted and saved.
   * Useful to manipulate an item instance directly with the ui and send it to the api afterwards.
   * @return {{insurance: (null|*), img: (*|null), membersValidation: boolean, privamount: null, description: null, MaxReserveTime: (number|number), manual: null, customId: *, uid, public: (*|boolean|number|string|CryptoKey|boolean), coordinatesLongitude: *, LinkList, counterCoefficient: null, pubamount: null, address: null, MaxDistance: (number|number), telOnly: (*|number|string|boolean|boolean), billingMethod: (number), vat: null, active: boolean, minFee: null, coordinatesLatitude: *, ConnectedItemsList: *[], ort: null, unit: null, ReservationValidation: boolean, name: null, responsibleUid: *, cancellationPolicy: null, plz: null, responsibleCanEdit: (null|*|boolean)}}
   */
  getItemInApiFormat () {
    const mainImage = this.getItemImages().getMainImage()
    const categoriesList = this.getItemCategories().getCategories().map((category) => category.id)
    const itemsList = this.connectedItems.getItems().map((item) => item.id)
    const uid = this.owner && this.owner.id ? this.owner.id : store.state.appUserInstance.getId()
    return {
      name: this.name,
      uid,
      unit: this.unit.id,
      public: this.public,
      address: this.getAddress(),
      plz: this.getZip(),
      ort: this.getCity(),
      privamount: NumberHelper.secureParseFloat(this.privateAmount),
      pubamount: NumberHelper.secureParseFloat(this.publicAmount),
      img: mainImage ? mainImage.link : null,
      description: this.description,
      active: true,
      MaxDistance: this.maxDistance ? this.maxDistance * 1000 : 0, // km => m
      MaxReserveTime: this.maxTime ? this.maxTime * 60 * 60 : 0, // h => sec
      billingMethod: this.hasCounter === true ? 1 : 0,
      ReservationValidation: this.reservationValidation,
      membersValidation: this.membersValidation,
      LinkList: categoriesList,
      ConnectedItemsList: itemsList,
      telOnly: this.telOnly,
      manual: this.manual === null ? '' : this.manual,
      insurance: this.insurance,
      responsibleUid: this.responsible ? this.responsible.uid : store.state.appUserInstance.getId(),
      responsibleCanEdit: !!(this.responsibleCanEdit),
      vat: this.vat === null ? config.standardVatId : this.vat,
      minFee: this.minFee === null ? 0 : NumberHelper.secureParseFloat(this.minFee),
      cancellationPolicy: this.cancellationPolicy === null ? 1 : this.cancellationPolicy,
      counterCoefficient: this.counterCoefficient === null ? 1 : NumberHelper.secureParseFloat(this.counterCoefficient),
      coordinatesLongitude: this.getLocation().getLongitudeApi(),
      coordinatesLatitude: this.getLocation().getLatitudeApi(),
      customId: this.customId,
    }
  }

  getCustomFields () {
    return this.customFields
  }

  // CUSTOM FIELDS
  // get the value of a custom fields key
  getCustomFieldValue (key) {
    let customField = this.customFields.getCustomField(key)

    if (customField) return customField.getValue()
    else return null
  }
}
