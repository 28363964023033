<template>
  <div>
    <!-- if no items were found or loaded -->
    <v-row
      v-show="!items"
    >
      <v-col class="col-12">
        {{$t('reservation.noItemsFound')}}
      </v-col>
    </v-row>

    <!-- new grid -->
    <v-row class="justify-center break-word">
      <v-col
        v-for="item in filteredItems()"
        v-show="item.isActive()"
        :key="item.id"
        class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-5 px-0 py-2"
        :class="{'greyedOut': !item.canISeeTheMachine()}"
      >
        <item-preview
          :item="item"
          :discounts="discounts"
          :hide-reservation-btn="hideReservationBtn"
          :search-start="searchStart"
          :search-end="searchEnd"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ItemPreview from '@/components/items/ItemPreview.vue'
import Discounts from '@/classes/Modules/Discounts/Discounts'

export default {
  name: 'items-grid',

  components: {
    ItemPreview
  },

  props: {
    items: {
      required: false
    },

    searchShow: {
      type: Boolean,
      required: false,
      default: true
    },

    hideReservationBtn: {
      type: Boolean,
      required: false,
      default: false
    },

    // search periode (for advanced search)
    searchStart: {
      type: Number,
      required: false,
      default: null
    },

    searchEnd: {
      type: Number,
      required: false,
      default: null
    }
  },

  data () {
    return {
      search: '',
      discounts: new Discounts()
    }
  },
  watch: {
    async items () {
      await this.discounts.loadDiscountsByItems(this.items)
    }
  },

  methods: {
    // filter items by the search string
    filteredItems () {
      if (this.items && this.items.items) {
        return this.items.getItems().filter((item) => {
          return (!item.hasOwnProperty('reservations') || item.hasOwnProperty('reservations')) &&
            (item.getName().toLowerCase().indexOf(this.search.toLowerCase()) >= 0 ||
              item.getDescription().toLowerCase().indexOf(this.search.toLowerCase()) >= 0)
        })
      } else {
        return []
      }
    }
  }
}

</script>

<style lang="scss">
  .fa-star {
    color: map-get($orange, base);
  }

  .fa-star-o {
    color: white;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: map-get($orange, base);
  }

  .v-window__prev, .v-window__next {
    margin: 0 0 !important;
  }
</style>
