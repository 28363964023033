import config from '../config'

export default {
  routes: [
    {
      path: '*',
      redirect: {
        name: 'home'
      }
    },
    {
      name: 'home',
      path: '/home',
      component: () => import('../views/home/Home.vue'),
      props: true
    },

    {
      name: 'Dashboard',
      path: '/dashboard',
      alias: '/dashboard/:reservationId',
      component: () => import('../components/dashboard/Dashboard.vue'),
      props: true,
      meta: {
        minGid: config.user.employee
      }
    },

    /**
     * Menu: Lease
     */
    // all reservations
    {
      name: 'Reservations',
      path: '/dashboard/archive',
      component: () => import('../views/lease/OverviewLease.vue'),
      props: true,
      meta: {
        minGid: config.user.normal
      }
    },
    // employee items
    {
      name: 'EmployeeItems',
      path: '/items/employee',
      component: () => import('../components/items/Employee.vue'),
      props: true,
      meta: {
        minGid: config.user.employee
      }
    },

    /**
     * Menu: Rent
     */
    // overview
    {
      name: 'OverviewRent',
      path: '/rent/overview',
      component: () => import('../views/rent/OverviewRent.vue'),
      props: true,
      meta: {
        minGid: config.user.normal
      }
    },

    // my items
    {
      name: 'Items',
      path: '/items/my',
      component: () => import('../components/items/Items.vue'),
      props: true,
      meta: {
        minGid: config.user.normal
      }
    },

    // items in responsiveness
    {
      name: 'Responsible',
      path: '/items/responsible',
      component: () => import('../components/items/Responsible.vue'),
      props: true,
      meta: {
        minGid: config.user.normal
      }
    },

    // items in discounts
    {
      name: 'Discount',
      path: '/discount',
      component: () => import('../components/discount/Overview.vue'),
      meta: {
        minGid: config.user.normal
      }
    },

    // add discount form
    {
      name: 'AddDiscount',
      path: '/discount/create',
      component: () => import('../components/discount/DiscountForm'),
      meta: {
        minGid: config.user.normal
      }
    },

    // edit discount form
    {
      name: 'UpdateDiscount',
      path: '/discount/:id',
      props: true,
      component: () => import('../components/discount/DiscountForm'),
      meta: {
        minGid: config.user.normal
      }
    },

    // conflicting reservations
    {
      name: 'ConflictingReservations',
      path: '/conflicts/overview',
      component: () => import('../components/conflicts/ConflictingReservationsView.vue'),
      props: true,
      meta: {
        minGid: config.user.normal
      }
    },

    /**
     * Menu: Team
     */
    {
      name: 'TeamMainMenuEntry',
      path: '/team',
      meta: {
        // fake route
        inactive: true,
        minGid: config.user.normal
      }
    },
    {
      name: 'Members',
      path: '/team/member',
      component: () => import('../components/teams/members/Members.vue'),
      props: true,
      meta: {
        minGid: config.user.normal
      }
    },
    {
      name: 'Memberships',
      path: '/team/memberships',
      component: () => import('../components/teams/memberships/Memberships.vue'),
      props: true,
      meta: {
        minGid: config.user.normal
      }
    },
    {
      name: 'Employee',
      path: '/team/employee',
      component: () => import('../components/teams/employee/Employee.vue'),
      props: true,
      meta: {
        minGid: config.user.normal
      }
    },

    /**
     * Menu: incoming invoice
     */
    {
      name: 'incomingInvoices',
      path: '/billing/incoming',
      component: () => import('../components/billing/IncomingInvoices.vue'),
      props: true,
      meta: {
        minGid: config.user.normal
      }
    },

    /**
     * Menu: Billing
     */
    {
      name: 'Billing',
      path: '/billing/draft',
      component: () => import('../components/billing/Billing.vue'),
      props: true,
      meta: {
        minGid: config.user.normal
      }
    },
    {
      name: 'PublishedBillings',
      path: '/billing/confirmed',
      component: () => import('../components/billing/publishedBillings/PublishedBillingsView.vue'),
      props: true,
      meta: {
        minGid: config.user.normal
      }
    },

    /**
     * Menu: Settings
     */
    {
      name: 'settings',
      path: '/user/settings',
      component: () => import('../components/settings/MySettings.vue'),
      props: true,
      meta: {
        minGid: config.user.normal
      }
    },

    /**
     * Menu: Admin
     */
    {
      name: 'admin',
      path: '/admin/overview',
      component: () => import('../components/admin/AdminDashboard.vue'),
      props: true,
      meta: {
        minGid: config.user.office
      }
    },

    /**
     * OTHER
     */
    /* feedback */
    {
      name: 'Feedback',
      path: '/feedback',
      component: () => import('../components/feedback/Feedback.vue'),
      props: true,
      meta: {
        minGid: config.user.employee
      }
    },

    /* auth */
    {
      name: 'Login',
      path: '/auth/login',
      component: () => import('../components/auth/login/Login.vue'),
      props: true
    },

    // logout
    {
      name: 'Logout',
      path: '/logout',
      component: () => import('../components/layout/Logout.vue'),
      props: true,
      meta: {
        minGid: config.user.employee
      }
    },

    /* push notification url */
    {
      name: 'PushNotification',
      path: '/push',
      component: () => import('../components/auth/login/Login.vue'),
      props: true
    },
    {
      name: 'PushNotificationId',
      path: '/push/:id',
      component: () => import('../components/auth/login/Login.vue'),
      props: true
    },
    {
      name: 'PushCustomNotification',
      path: '/push/customNotification/:id',
      component: () => import('../components/auth/login/Login.vue'),
      props: true
    },

    /** Password routings **/
    {
      name: 'PwReset',
      path: '/auth',
      component: () => import('../components/auth/PwReset.vue'),
      props: true
    },
    {
      name: 'PwSet',
      path: '/auth/set',
      component: () => import('../components/auth/set/PwSet.vue'),
      props: true
    },
    {
      name: 'Registration',
      path: '/auth/registration',
      component: () => import('../components/auth/registration/Registration.vue'),
      props: true
    },

    /* invitations */
    {
      name: 'Invitation',
      path: '/invite/:code',
      component: () => import('../views/share/Invitation.vue'),
      props: true
    },

    /* permissions */
    {
      name: 'PermissionDenied',
      path: '/changeUser/PermissionDenied',
      component: () => import('../components/changeUser/PermissionDenied.vue'),
      props: true
    },

    /* items */
    {
      name: 'ItemDetail',
      path: '/items/:id/detail',
      component: () => import('../components/reservations/ItemDetail.vue'),
      props: true,
      meta: {
        minGid: config.user.employee
      }
    },

    {
      name: 'ItemPublicView',
      path: '/public/item/:id',
      component: () => import('../components/items/ItemPublicDetails.vue'),
      props: true,

    },
    // edit my item
    {
      name: 'ItemEditView',
      path: '/item/edit',
      component: () => import('../views/lease/ItemEditView.vue'),
      props: true,
      meta: {
        minGid: config.user.normal
      }
    },
    // create new item
    {
      name: 'ItemCreateView',
      path: '/item/create',
      component: () => import('../views/lease/ItemCreateView.vue'),
      props: true,
      meta: {
        minGid: config.user.normal
      }
    },

    /* user profiles */
    {
      name: 'UserProfile',
      path: '/user/profile',
      component: () => import('../components/user/UserProfile.vue'),
      props: true,
      meta: {
        minGid: config.user.employee
      }
    },

    /* reservations */
    {
      name: 'SingleReservationView',
      path: '/reservation/:id',
      component: () => import('../views/reservations/SingleReservationView.vue'),
      props: true,
      meta: {
        minGid: config.user.employee
      }
    },
    {
      name: 'ReservationCloseView',
      path: '/reservation/:id/close',
      component: () => import('../views/reservations/ReservationCloseView.vue'),
      props: true,
      meta: {
        minGid: config.user.employee
      }
    },
    {
      name: 'ReservationComments',
      path: '/reservation/:id/comments',
      component: () => import('../views/reservations/ReservationComments.vue'),
      props: true,
      meta: {
        minGid: config.user.employee
      }
    },
    {
      name: 'makeReservation',
      path: '/booking/create',
      component: () => import('../components/reservations/Reservation.vue'),
      props: true,
      meta: {
        minGid: config.user.employee
      }
    },
    /* notifications */
    {
      name: 'Notifications',
      path: '/notifications',
      component: () => import('../views/notifications/NotificationsView.vue'),
      props: true,
      meta: {
        minGid: config.user.normal
      }
    },
    /* custom notifications */
    {
      name: 'CreateCustomNotification',
      path: '/notifications/custom/create',
      component: () => import('../views/customNotifications/CreateCustomNotification.vue'),
      props: true,
      meta: {
        minGid: config.user.normal
      }
    },
    {
      name: 'CustomNotificationView',
      path: '/notifications/custom/:id',
      component: () => import('../views/customNotifications/CustomNotificationView.vue'),
      props: true,
      meta: {
        minGid: config.user.normal
      }
    },
    {
      name: 'UpdateCustomNotification',
      path: '/notifications/custom/update/:id',
      component: () => import('../views/customNotifications/UpdateCustomNotification.vue'),
      props: true,
      meta: {
        minGid: config.user.normal
      }
    },

    /* billing */
    {
      name: 'Invoices',
      path: '/billing/:bid',
      component: () => import('../components/billing/Invoices.vue'),
      props: true,
      meta: {
        minGid: config.user.normal
      }
    },
    {
      name: 'InvoicesDraft',
      path: '/billing/draft/:bid',
      component: () => import('../components/billing/Invoices.vue'),
      props: true,
      meta: {
        minGid: config.user.normal
      }
    },
    {
      name: 'SentInvoice',
      path: '/invoice/preview/:invoiceId',
      component: () => import('../components/billing/SentInvoice.vue'),
      props: true,
      meta: {
        minGid: config.user.normal
      }
    },
    {
      name: 'CreateNewBilling',
      path: '/billing/create',
      component: () => import('../components/billing/createNewBilling/CreateNewBilling.vue'),
      props: true,
      meta: {
        minGid: config.user.normal
      }
    },

    /* admin */
    {
      name: 'CaptureCustomer',
      path: '/admin/customer/capture',
      component: () => import('../components/admin/customer/CaptureCustomer.vue'),
      props: true,
      meta: {
        minGid: config.user.office
      }
    },
    {
      name: 'ManageCustomer',
      path: '/admin/customer/overview',
      component: () => import('../components/admin/customer/ManageCustomer.vue'),
      props: true,
      meta: {
        minGid: config.user.office
      }
    },
    {
      name: 'editCustomer',
      path: '/admin/customer/edit/:uid',
      component: () => import('../views/admin/EditCustomer.vue'),
      props: true,
      meta: {
        minGid: config.user.office
      }
    },
    {
      name: 'CustomerLogin',
      path: '/customer/login/:authtoken',
      component: () => import('../components/auth/login/Login.vue'),
      props: true
    },

    /**
     * share farmx
     */
    {
      name: 'Share',
      path: '/share',
      component: () => import('../views/share/Share.vue'),
      props: true,
      meta: {
        minGid: config.user.normal
      }
    }

    // template
    /*
      {
        name: '',
        path: '',
        component: () => import('../components/.vue'),
        meta: {
          minGid: ''
        }
      },

    */
  ]
}
