<template>
  <div>
    <v-row
      v-if="itemInstance"
      class="align-center"
    >
      <!-- prices -->
      <v-col
        :class="[alignDiscountBeneath ? 'col-12' : 'col-7']"
        class="py-0 pr-1"
      >
        <!-- private price -->
        <div :class="privatePriceClasses" v-if="itemInstance && itemInstance.getPrice().public !== null">
          {{$t('v6.noMemberTarif')}}: chf {{itemInstance.getPrice().public}}/{{itemInstance.getUnitShort()}}
        </div>

        <!-- public price -->
        <div :class="publicPriceClasses" v-if="itemInstance && itemInstance.getPrice().private !== null">
          {{$t('v6.memberTarif')}}: chf {{itemInstance.getPrice().private}}/{{itemInstance.getUnitShort()}}
        </div>

        <!-- min fee -->
        <div :class="minFeeClasses" v-if="itemInstance.getMinFee() && itemInstance.getMinFee() > 0">
          {{$t('v6.minFeePerReservation')}}: chf {{itemInstance.getMinFee()}}
        </div>
      </v-col>

      <!-- discount -->
      <v-col
        v-if="discountsByItem && discountsByItem.length"
        :class="[alignDiscountBeneath ? 'col-12 pl-3' : 'col-5 pl-1', discountClasses]"
        class="clickable py-0"
        @click.capture.stop="$refs.discountModal.open()"
      >
        <span class="fal fa-tag mr-1"/>
        <span class="hover">{{$t('v13.withDiscount')}}</span>
      </v-col>
    </v-row>

    <!-- modal for discount information -->
    <modal
      ref="discountModal"
      hide-ok
      :cancel-text="$t('v4.close')"
      v-if="discountsByItem"
    >
      <div slot="title">{{$t('v13.discounts')}}</div>

      <!-- explanaition text -->
      <v-row>
        <v-col class="col-12">
          <div>
            {{$t('v13.youMayGetDiscount')}}
          </div>
        </v-col>
      </v-row>

      <!-- discount level titles -->
      <v-row
        class="mt-6 black white--text no-gutters pa-1"
      >
        <!-- lower threshold -->
        <v-col class="col-3">
          {{$t('v13.discountForm.levelStart')}}
        </v-col>

        <!-- higher threshold -->
        <v-col class="col-3">
          {{$t('v13.discountForm.levelEnd')}}
        </v-col>

        <!-- discount per unit -->
        <v-col class="col-6">
          {{$t('v13.discount')}}
        </v-col>
      </v-row>

      <!-- iterate discounts -->
      <div
        v-for="(discount, discountIndex) in discountsByItem"
        :key="discountIndex"
        class="mt-4"
      >
        <v-row
          v-for="(discountLevel, discountLevelIndex) in discount.getDiscountLevels()"
          :key="discountLevelIndex"
          class="pa-1"
        >
          <!-- lower threshold -->
          <v-col class="col-3 py-0">
            {{discountLevel.lowerThreshold}} {{itemInstance.getUnitShort()}}
          </v-col>

          <!-- higher threshold -->
          <v-col class="col-3 py-0">
            {{discountLevel.upperThreshold}} {{itemInstance.getUnitShort()}}
          </v-col>

          <!-- discount per unit -->
          <v-col class="col-6 py-0">
            {{discountLevel.discountPerQuantity}} chf / {{itemInstance.getUnitShort()}}
          </v-col>
        </v-row>
      </div>
    </modal>
  </div>
</template>

<script>
  import Discounts from '@/classes/Modules/Discounts/Discounts'

  export default {
    name: 'ItemPrices',

    props: {
      // instance of item class
      itemInstance: {
        required: true,
        default: () => {
          return null
        }
      },

      /**
       * Discounts instance
       */
      discounts: {
        required: false,
        type: Discounts,
        default: undefined
      },

      // html classes for private price
      privatePriceClasses: {
        type: String,
        required: false,
        default: ''
      },

      // html classes for public price
      publicPriceClasses: {
        type: String,
        required: false,
        default: ''
      },

      // html classes for minimal fee
      minFeeClasses: {
        type: String,
        required: false,
        default: ''
      },
      // html classes for discount
      discountClasses: {
        type: String,
        required: false,
        default: 'red--text'
      },
      alignDiscountBeneath: {
        type: Boolean,
        required: false,
        default: true
      }
    },

    computed: {
      discountsByItem () {
        if (this.itemInstance && this.discounts && this.discounts.getDiscountsByItemId(this.itemInstance.getId())) {
          return this.discounts.getDiscountsByItemId(this.itemInstance.getId())
        } else return null
      }
    }
  }
</script>
