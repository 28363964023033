import store from '@/store/store'
import Items from '@/classes/Items'
import axios from 'axios'
import Item from '../../../classes/Item'
import Vue from 'vue'
import ItemReservable from '../../../classes/Items/ItemReservable'
import definitions from '../../../../definitions'
const {state} = store

export class ItemsServices {
  /* global i18n EventBus api */
  /**
   * Fetch all items of my user
   * @param page
   * @param limit
   * @param fetchCustomFields
   * @param endPoint
   * @return {Promise<{}>}
   */
  static async getAll (
    {
      page = 1,
      limit = state.paginationLimit,
      fetchCustomFields = false
    }
  ) {
    try {
      EventBus.$emit('spinnerShow')
      const getCount = page === 1
      const apiResponse = await axios.get(`${api}myitems/${page}/${limit}/${getCount}`)
      const items = new Items({apiItems: apiResponse.data.rows}, apiResponse.data.count)
      if (fetchCustomFields) {
        await items.fetchCustomFields()
      }
      EventBus.$emit('spinnerHide')
      return items
    } catch (e) {
      Vue.notify({
        title: i18n.t('notify.itemLoadFailed'),
        type: 'error'
      })
      EventBus.$emit('spinnerHide')
    }
  }

  /**
   * load public item
   * @param itemId
   * @return {Promise<*>}
   */
  static async getOnePublic (itemId) {
    try {
      EventBus.$emit('spinnerShow')
      // eslint-disable-next-line no-undef
      const apiResponse = await axios.get(`${api}public/item/${itemId}`)
      const itemInstance = new Item({...apiResponse.data, id: parseInt(itemId)})
      EventBus.$emit('spinnerHide')
      return itemInstance
    } catch (e) {
      Vue.notify({
        title: i18n.t('notify.itemLoadFailed'),
        type: 'error'
      })
      EventBus.$emit('spinnerHide')
      return null
    }
  }

  /**
   * Fetch item (need authentication)
   * @param itemId
   * @param fetchCustomFields
   * @return {Promise<*|null>}
   */
  static async getOne (itemId, fetchCustomFields = false) {
    try {
      EventBus.$emit('spinnerShow')
      const response = await axios.get(`${api}item/full/${itemId}`)
      const itemInstance = new Item(response.data)
      if (fetchCustomFields) {
        await itemInstance.customFields.fetch([itemInstance], definitions.customFields.types.item)
      }
      EventBus.$emit('spinnerHide')
      return itemInstance
    } catch (e) {
      EventBus.$emit('spinnerHide')
      Vue.notify({
        title: i18n.t('notify.itemLoadFailed'),
        type: 'error'
      })
      return null
    }
  }

  /**
   * Get connected items list of a single item
   * @param itemId
   * @param zip
   * @param startDate
   * @param endDate
   * @return {Promise<*|boolean>}
   */
  static async getAllConnected (itemId, zip, startDate = 0, endDate = 0) {
    EventBus.$emit('spinnerShow')
    try {
      const response = await axios.get(`${api}ConnectedItems/${itemId}/${zip}/${startDate}/${endDate}`)
      const items = new Items()
      items.parseApiItems(response.data)
      EventBus.$emit('spinnerHide')
      return items
    } catch (error) {
      EventBus.$emit('spinnerHide')
      Vue.notify({
        title: i18n.t('notify.error') + ' ' + error,
        type: 'error'
      })
      return false
    }
  }

  /**
   * Search for an item
   * @param page
   * @param searchFor
   * @return {Promise<*>}
   */
  static async search (page = 1, searchFor) {
    const getCount = page === 1
    const limit = state.paginationLimit
    let apiResponse
    try {
      EventBus.$emit('spinnerShow')
      apiResponse = await axios.get(`${api}myitems/${page}/${limit}/${getCount}?search=${searchFor}`)
      const items = new Items({apiItems: apiResponse.data.rows}, apiResponse.data.count)
      await items.fetchCustomFields()
      EventBus.$emit('spinnerHide')
      return items
    } catch (e) {
      Vue.notify({
        title: i18n.t('notify.itemLoadFailed'),
        type: 'error'
      })
      EventBus.$emit('spinnerHide')
    }
  }

  /**
   * Create a new item (Type: Item)
   * @param item: Item
   * @return {Promise<Boolean>}
   */
  static async create (item) {
    EventBus.$emit('spinnerShow')
    try {
      // get api compatible item object
      const itemForApi = item.getItemInApiFormat()
      const response = await axios.post(`${api}item`, itemForApi)
      const itemId = response.data.iid
      Vue.notify({
        title: i18n.t('notify.itemAdded'),
        type: 'success'
      })
      EventBus.$emit('spinnerHide')

      // link all slider images with new created item, if any
      const imagesArray = item.getItemImages().getImages(false, false)
      if (imagesArray.length > 0) {
        await ItemsServices.addSliderImages(itemId, imagesArray)
      }
      return itemId
    } catch (error) {
      switch (error.response.status) {
        case 901: // invalid coordinates
          Vue.notify({
            title: i18n.t('v10.coordinatesRequired'),
            type: 'error'
          })
          break
        default:
          Vue.notify({
            title: i18n.t('notify.itemNotAdded'),
            type: 'error'
          })
      }
      EventBus.$emit('spinnerHide')
      return false
    }
  }

  /**
   * Clone existing item and create a variant of it.
   * @param itemId
   * @return {Promise<Item|boolean>}
   */
  static async createVariant (itemId) {
    if (!itemId) {
      throw new Error('No item id provided you wish to copy.')
    }
    try {
      EventBus.$emit('spinnerShow')
      const response = await axios.post(`${api}item/${itemId}/variant`)
      const item = new Item(response.data)
      EventBus.$emit('spinnerHide')
      return item
    } catch (e) {
      EventBus.$emit('spinnerHide')
      Vue.notify({
        title: i18n.t('itemsServices.variantCreationFailed'),
        type: 'error'
      })
      return false
    }
  }

  /**
   * Patch existing item.
   * @param item
   * @return {Promise<boolean>}
   */
  static async update (item) {
    EventBus.$emit('spinnerShow', i18n.t('items.updateItem'))
    try {
      const payload = item.getItemInApiFormat()
      await axios.patch(`${api}item/${item.id}`, payload)
      Vue.notify({
        title: i18n.t('notify.itemUpdated'),
        type: 'success'
      })
      return true
    } catch (error) {
      const code = error.response.status
      switch (code) {
        case 490:
          Vue.notify({
            title: i18n.t('notify.counterCanNotBeChangedBecauseOfReservations'),
            type: 'error'
          })
          break
        case 901:
          Vue.notify({
            title: i18n.t('v10.coordinatesRequired'),
            type: 'error'
          })
          break
        default:
          Vue.notify({
            title: i18n.t('notify.error') + ' ' + code,
            type: 'error'
          })
      }
      return false
    } finally {
      EventBus.$emit('spinnerHide')
    }
  }

  /**
   * Check if an item is reservable
   * @param itemId
   * @return {Promise<boolean|ItemReservable>}
   */
  static async isReservable (itemId) {
    EventBus.$emit('spinnerShow')
    try {
      const response = await axios.get(`${api}item/${itemId}/isReservable`)
      const itemReservable = new ItemReservable(response.data)
      EventBus.$emit('spinnerHide')
      return itemReservable
    } catch (error) {
      EventBus.$emit('spinnerHide')
      return new ItemReservable()
    }
  }

  /**
   * Update main image of an item
   * @param itemId: number
   * @param image: Image
   * @return {Promise<boolean>}
   */
  static async updateMainImage (itemId, image) {
    EventBus.$emit('spinnerShow')
    try {
      // patch item img url
      await axios.patch(`${api}item/${itemId}`, {
        img: image.link
      })
      Vue.notify({
        title: i18n.t('notify.imageUploaded'),
        type: 'success'
      })
      EventBus.$emit('spinnerHide')
      return true
    } catch (error) {
      Vue.notify({
        title: i18n.t('notify.imageNotUploaded'),
        type: 'error'
      })
      EventBus.$emit('spinnerHide')
      return false
    }
  }

  /**
   * Mark item as my favorite
   * @param itemId
   * @return {Promise<boolean>}
   */
  static async markAsFavorite (itemId) {
    if (!itemId) {
      throw new Error('Item id not provided in markAsFavorite() methode!')
    }
    EventBus.$emit('spinnerShow')
    try {
      await axios.post(`${api}items/favorite`, {
        iid: itemId
      })
      EventBus.$emit('spinnerHide')
      Vue.notify({
        title: i18n.t('notify.favoriteAdded'),
        type: 'success'
      })
      return true
    } catch (error) {
      EventBus.$emit('spinnerHide')
      Vue.notify({
        title: i18n.t('notify.failFavoriteAdd'),
        text: error.response.status,
        type: 'error'
      })
      return false
    }
  }

  /**
   * Remove item from my favorites
   * @param itemId
   * @return {Promise<boolean>}
   */
  static async unmarkAsFavorite (itemId) {
    if (!itemId) {
      throw new Error('Item id not provided in unmarkAsFavorite() methode!')
    }
    EventBus.$emit('spinnerShow')
    try {
      await axios.delete(`${api}items/favorite/${itemId}`)
      EventBus.$emit('spinnerHide')
      Vue.notify({
        title: i18n.t('notify.favoriteRemoved'),
        type: 'success'
      })
      return true
    } catch (error) {
      EventBus.$emit('spinnerHide')
      Vue.notify({
        title: i18n.t('notify.failFavoriteRemove'),
        text: error.response.status,
        type: 'error'
      })
      return false
    }
  }

  /**
   * Add a new image to the item
   * @param itemId
   * @param image
   * @param notify
   * @return {Promise<boolean>}
   */
  static async addSliderImage (itemId, image, notify = true) {
    EventBus.$emit('spinnerShow')
    try {
      // patch item img url
      await axios.post(`${api}item/image`, {
        iid: itemId,
        link: image.link
      })
      if (notify) {
        Vue.notify({
          title: i18n.t('notify.imageUploaded'),
          type: 'success'
        })
      }
      EventBus.$emit('spinnerHide')
      return true
    } catch (error) {
      Vue.notify({
        title: i18n.t('notify.imageNotUploaded'),
        type: 'error'
      })
      EventBus.$emit('spinnerHide')
      return false
    }
  }

  /**
   * link multiple slider images with an item
   * @param itemId
   * @param imagesArray
   * @return {Promise<void>}
   */
  static async addSliderImages (itemId, imagesArray) {
    await Promise.all(
      imagesArray.map((image) => {
        ItemsServices.addSliderImage(itemId, image, false)
      })
    )
  }

  /**
   * Update an existing image
   * @param itemId
   * @param image
   * @return {Promise<boolean>}
   */
  static async updateSliderImage (itemId, image) {
    EventBus.$emit('spinnerShow')
    try {
      // patch item img url
      await axios.patch(`${api}item/image/${image.id}`, {
        iid: itemId,
        link: image.link
      })
      Vue.notify({
        title: i18n.t('notify.imageUploaded'),
        type: 'success'
      })
      EventBus.$emit('spinnerHide')
      return true
    } catch (error) {
      Vue.notify({
        title: i18n.t('notify.imageNotUploaded'),
        type: 'error'
      })
      EventBus.$emit('spinnerHide')
      return false
    }
  }

  /**
   * Delete a slider image
   * @param itemId
   * @param image
   * @return {Promise<boolean>}
   */
  static async deleteSliderImage (itemId, image) {
    EventBus.$emit('spinnerShow')
    try {
      // patch item img url
      await axios.delete(`${api}item/image/${image.id}`, {
        data: {
          iid: itemId,
        }
      })
      Vue.notify({
        title: i18n.t('notify.imageDeleted'),
        type: 'success'
      })
      EventBus.$emit('spinnerHide')
      return true
    } catch (error) {
      Vue.notify({
        title: i18n.t('notify.imageNotDeleted'),
        type: 'error'
      })
      EventBus.$emit('spinnerHide')
      return false
    }
  }

  /**
   * Delete main image by setting image to null
   * @param itemId
   * @return {Promise<boolean>}
   */
  static async deleteMainImage (itemId) {
    EventBus.$emit('spinnerShow')
    try {
      // patch item img url
      await axios.patch(`${api}item/${itemId}`, {
        img: null
      })
      Vue.notify({
        title: i18n.t('notify.imageDeleted'),
        type: 'success'
      })
      EventBus.$emit('spinnerHide')
      return true
    } catch (error) {
      Vue.notify({
        title: i18n.t('notify.imageNotDeleted'),
        type: 'error'
      })
      EventBus.$emit('spinnerHide')
      return false
    }
  }
}
