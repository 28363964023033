<script>
import ItemsGridDescription from '@/components/reservations/itemsGrid/ItemsGridDescription.vue'
import ItemsGridFavorite from '@/components/reservations/itemsGrid/ItemsGridFavorite.vue'
import ItemsGridInfo from '@/components/reservations/itemsGrid/ItemsGridInfo.vue'
import Item from '@/classes/Item'
import Discounts from '@/classes/Modules/Discounts/Discounts'

export default {
  name: 'ItemPreviewAtom',
  components: {ItemsGridInfo, ItemsGridFavorite, ItemsGridDescription},
  props: {
    item: {
      required: true,
      type: Item
    },
    discounts: {
      required: false,
      type: Discounts,
      default: undefined
    },
    hideReservationBtn: {
      type: Boolean,
      required: false,
      default: false
    },
    hideFavoritBtn: {
      type: Boolean,
      required: false,
      default: false
    },
    // prevents user to be routed to the item's detail
    preventVisitItem: {
      type: Boolean,
      required: false,
      default: false
    },
    searchStart: {
      type: Number,
      required: false,
      default: null
    },
    searchEnd: {
      type: Number,
      required: false,
      default: null
    }
  },
  methods: {
    // shows the item details and stores the search details in the store
    showItemDetail (item, ignoreDistance = false) {
      if (this.preventVisitItem) {
        return
      }
      if (item.canISeeTheMachine(true, ignoreDistance)) {
        // save tmp search
        this.$emit('ItemGrid:saveTmpSearch')
        this.$router.push({name: 'ItemDetail', params: {id: item.id}})
      }
    },
    selectMachine (item) {
      /* global EventBus */
      EventBus.$emit('selectMachine', item)
    }
  }
}
</script>

<template>
  <v-card
    light
    :hover="item.canISeeTheMachine()"
    class="mx-3 my-4"
    @click.prevent="showItemDetail(item)"
  >
    <!-- image -->
    <v-row>
      <v-col class="col-6 col-md-12 py-0">
        <v-img
          class="border-top-left-radius"
          :class="[{'border-top-right-radius': $vuetify.breakpoint.smdAndUp}]"
          aspect-ratio="1"
          :src="item.getImg()"
        >
          <!-- variant -->
          <v-row v-if="item.isVariantOf()" class="no-gutters">
            <v-col class="col-3">
              <items-grid-favorite
                v-if="!hideFavoritBtn"
                :item="item"
              />
            </v-col>
            <v-col class="col-6 text-right">

            </v-col>
          </v-row>

          <!-- main item - not he variant -->
          <v-row
            v-else
            class="fill-height justify-end"
          >
            <!-- favorite star -->
            <v-col
              class="col-12 text-left pl-5"
            >
              <items-grid-favorite
                v-if="!hideFavoritBtn"
                class="pt-2"
                :item="item"
              />
            </v-col>

            <!-- logo of user -->
            <v-col
              v-if="item.getOwner().getLogo()"
              class="col-7 align-self-end text-center white pa-2"
            >
              <v-img
                :max-height="$vuetify.breakpoint.smAndDown ? 18 : 23"
                contain
                :src="item.getOwner().getLogo()"
              />
            </v-col>
          </v-row>
        </v-img>
      </v-col>

      <!-- title and description -->
      <v-col
        class="col-6 col-md-12 pl-0 pl-md-5 pr-md-5 py-0"
      >
        <items-grid-description
          :item="item"
          :hide-reservation-btn="hideReservationBtn"
          @selectItem="selectMachine(item)"
        />
      </v-col>
    </v-row>

    <!-- distance, address and prices -->
    <items-grid-info
      :item="item"
      :discounts="discounts"
      :search-start="searchStart"
      :search-end="searchEnd"
    />
  </v-card>
</template>
